import React, { useState } from 'react';
import { Box } from '@mui/material';
function Error({ message,show,handleShow }) {
  
    const style = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9999',
        backdropFilter: 'blur(1px)', // Adding a blur effect to the background
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        borderRadius:'10px'
      };

  return (
    <>
      {show && (
    <Box sx={style}>
    
    <div className="flex items-center">
     <div className="flex flex-col justify-center p-12 bg-white items-center rounded-sm space-x-2">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-xl leading-6 font-medium text-gray-900" id="modal-headline">Error</h3>
                  <div className="mt-2">
                    <p className="text-base text-gray-500">{message}</p>
                  </div>
                </div>
                <div className="mt-3 ">
                <button
                  className="flex items-center justify-center w-36 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                  onClick={ handleShow }
                >
                  OK
                </button>
              </div>
              </div>
             
            
          </div>
        </Box>
      )}
    </>
  );
}

export default Error;
