import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store'; // Assuming store is exported from './store'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';




// Render the application with both providers
const msalConfig = {
  auth: {
    clientId: 'fa362d0d-ca98-4c2c-a59b-4aaf39d91775',
    authority: 'https://login.microsoftonline.com/8900c5e2-270f-4ca0-9a2c-85889720d198',
   //redirectUri: 'http://localhost:3000'
  redirectUri: 'https://fcgrn.wajesmarthrms.website/'
  }
};

const pca = new PublicClientApplication(msalConfig);

// Render the application with both providers
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <App />
        <ToastContainer />
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
