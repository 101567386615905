import React, { useState } from 'react';
import Logo from '../assets/images/FoodConcepts_Logo-1 1.png';
import Avatar from '@mui/material/Avatar';
import { PublicClientApplication } from "@azure/msal-browser";
import { useNavigate,useLocation, Link  } from 'react-router-dom';
import AccountMenu from './Navdeopdown';
import { ReactComponent as Vector } from '../assets/images/Vector.svg';
import { ReactComponent as Vector1 } from '../assets/images/Vector (1).svg';
import { ReactComponent as Vector2 } from '../assets/images/Vector (2).svg';


const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedin = JSON.parse(localStorage.getItem("isLoggedin"));
    // You should probably implement a better route guard than the following if statement.
    if(!isLoggedin){
        navigate('/');
    }
    const [showSidebar, setShowSidebar]=useState(false)
    const [expand, setExpand] = useState(false);
    const name = localStorage.getItem("name");
    const userName = localStorage.getItem("username");
    const msalConfig = JSON.parse(localStorage.getItem("msalConfig"));

    const handleLogout = () => {
        const msalInstance = new PublicClientApplication(msalConfig);
        msalInstance.initialize()
            .then(()=>{
                const logoutRequest = {
                    account: msalInstance.getAccountByUsername(userName),
                // mainWindowRedirectUri: 'http://localhost:3000/',
                  mainWindowRedirectUri: 'https://fcgrn.wajesmarthrms.website/',
                }
                localStorage.clear();
                localStorage.setItem('isLoggedin', false);
                msalInstance.logoutPopup(logoutRequest);
            })
            .catch((error)=>{
                console.log(error)
            })
    };

    return (  <>
        {showSidebar ? (
            <div className="fixed inset-0 z-50 flex">
                <div className="text-base font-medium  box-shadow bg-white w-[80%] h-full">
                    <div className="flex justify-between">
                        <img src={Logo} className="h-16 mx-4" alt="Logo" />
                        <button
                            className="text-xl p-2"
                            onClick={() => setShowSidebar(false)}
                        >
                            &times;
                        </button>
                    </div>
                    <ul>
                        <li className={`sidebar-text text-sm mt-3 py-2 ${location.pathname === '/home' || location.pathname === '/grn' ? 'active' : ''}`}>
                            <Link className='flex gap-1 items-center' to='/home'>
                                <Vector className={`mx-2 icon ${location.pathname === '/home' || location.pathname === '/grn' ? 'active' : ''}`} />
                                <span>Create GRN</span>
                            </Link>
                        </li>
                        <li className={`sidebar-text mt-3 text-sm py-2 ${location.pathname === '/history' ? 'active' : ''}`}>
                            <Link className='flex gap-1 items-center' to='/history'>
                                <Vector1 className={`mx-2 icon ${location.pathname === '/history' ? 'active' : ''}`} />
                                <span>GRN History</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div
                    className="fixed inset-0 bg-black opacity-50"
                    onClick={() => setShowSidebar(false)}
                ></div>
            </div>
        ) : (
            <div className='md:w-[86%] md:ml-[14%] w-full flex header justify-between px-3'>
                <div className="flex md:items-center text-blue-900">
                    {/* <img src={Logo} className='h-14' alt="Logo" /> */}
                    <button
                        data-collapse-toggle="navbar-multi-level"
                        type="button"
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 mt-4 mr-4"
                        aria-controls="navbar-multi-level"
                        aria-expanded="false"
                        onClick={() => setShowSidebar(!showSidebar)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <span className='font-semibold text-lg '>GRN System</span>
                </div>
                <div className="flex items-center text-blue-900 text-base">
                    <Avatar sx={{ bgcolor: '#115FF5 ' }} />
                    <span className='user-details ml-2'>{`${name?.split(" ")[0]}`}</span>
                    <div className='dropdown-svg cursor-pointer' onClick={() => setExpand(!expand)}>
                        <AccountMenu SignOut={handleLogout} />
                    </div>
                </div>
            </div>
        )}
    </>
      
    );
}

export default Header;