import { Box } from "@mui/material";
import React from "react";
import { dotPulse } from 'ldrs'

const Loader=()=>{
    dotPulse.register()
    const style = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9999',
        backdropFilter: 'blur(1px)', // Adding a blur effect to the background
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
      };
    
return(
   <Box sx={style}>
    
     <div className="flex items-center">
      <div className="flex flex-col justify-center w-[259px] h-28 bg-white rounded-md items-center  space-x-2">
  
      <l-dot-pulse
  size="54"
  speed="1.3" 
  color="#1D3F77" 
></l-dot-pulse>
       <div className="italic text-gray-600 text-base my-1">Loading...</div>
      </div>
    </div>
    </Box>
)
}
export default Loader