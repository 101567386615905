import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";


const SignIn = () => {
    let username = "";
    const navigate = useNavigate();
    const msalConfig = {
        auth: {
            clientId: 'fa362d0d-ca98-4c2c-a59b-4aaf39d91775',
            authority: 'https://login.microsoftonline.com/8900c5e2-270f-4ca0-9a2c-85889720d198',
            //redirectUri: 'http://localhost:3000/',
            redirectUri: 'https://fcgrn.wajesmarthrms.website/',
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
            storeAuthStateInCookie: false, 
        },
    };

    useEffect(() => {
        localStorage.clear()
        const msalInstance = new PublicClientApplication(msalConfig);
        const loginRequest = {
            scopes: ["openid", "profile", "d8997d9e-547d-41d3-af3e-27f889804a08/vimp.users.read"],
        };

        msalInstance.initialize()
            .then(()=>{
                const currentAccounts = msalInstance.getAllAccounts();

                if (!currentAccounts || currentAccounts.length < 1) {
                    msalInstance.loginPopup(loginRequest)
                        .then((data) => {
                            console.log(data)
                            const currentAccounts = msalInstance.getAllAccounts();
                            username = currentAccounts[0].username;

                            // Handle login logic
                            localStorage.setItem("account", JSON.stringify(data.account));
                            localStorage.setItem("accessToken", data.accessToken);
                            localStorage.setItem("idToken", data.account.idToken);
                            localStorage.setItem("name", data.account.name);
                            localStorage.setItem("username", data.account.username);

                            localStorage.setItem('isLoggedin', true);
                            localStorage.setItem('msalConfig', JSON.stringify(msalConfig));
                            toast.success('Login successful');
                            navigate('/home');
                        }).catch(error => {
                            console.error("Silent Error: " + error);
                        });
                } else if (currentAccounts.length > 1) {
                    // Add your account choosing logic here
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    username = currentAccounts[0].username;
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }, [navigate, msalConfig]);

    return null;
};


export default SignIn;