import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import PageTitle from '../components/PageTitle';
import { useNavigate,useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Loader from '../components/Loader';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { NumericFormat } from 'react-number-format';
import { DateRangePicker } from 'rsuite';
import Select from 'react-select';
import { convertDateFormat } from './GrnPage';
import makeAnimated from 'react-select/animated';
import { ReactComponent as DownloadIcon } from '../assets/images/downloadIcon.svg';
import { handleLogout } from '../helper/Logout';
import Error from '../components/Error';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import SessionExpired from '../components/sessionExpired';
import { set } from 'rsuite/esm/utils/dateUtils';
import Pagination from '../components/Pagination';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className='flex'>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const History = () => {
  const [draftList, setDraftList] = useState([]);
  const [error, setError] = useState(null);
  const token=localStorage.getItem('accessToken')
  const [filteredCreatedList, setFilteredCreatedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState('');
  const [count, setCount] = useState('');
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState([])
  const [loading, setLoading] = useState(false);
  const name = localStorage.getItem("name");
  const userName = localStorage.getItem("username");
  const msalConfig = JSON.parse(localStorage.getItem("msalConfig"));
  const location=useLocation()
  useEffect(() => {
    // Retrieve draft and submitted data from local storage
    const storedDraftList = JSON.parse(localStorage.getItem('Drafted')) || [];
    // console.log(storedDraftList[0],'storeeddd')
    // const storedSubmittedList = JSON.parse(localStorage.getItem('Submitted')) || [];

    // Combine both lists
    // const combinedData = [...storedDraftList, ...storedSubmittedList];
    // setCombinedList(combinedData);

    // Set individual lists
    setDraftList(storedDraftList);
    // setSubmittedList(storedSubmittedList);
    getGRN(page,size)
  }, []);

  const animatedComponents = makeAnimated();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  // console.log(historyData[0],'historyyyyy')
  // Render action buttons based on item status
 
  // Filter the combined list based on search query
 
  console.log(historyData,'ggggg')
 // Function to filter historyData based on searchQuery

 useEffect(() => {
  const params = new URLSearchParams(location.search);
  const pageParam = params.get('page');
  const pageSizeParam = params.get('page_size');

  const newPage = pageParam ? Number(pageParam) : 1;
  const newPageSize = pageSizeParam ? Number(pageSizeParam) : 10;

  setPage(newPage);
  setSize(newPageSize);

  getGRN(newPage, newPageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [location.search]);

 const getGRN = async (page,size) => {

  setLoading(true);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/egrn/v1/grns?page=${page}&size=${size}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    console.log(response, "I am the res");
    setHistoryData(response.data.data.results);
    setCount(response.data.data.count)
    const totalPages=Math.ceil(response.data.data.count/size)
    setTotal(totalPages)
    Swal.close();
  } catch (error) {
    console.log(error, "PO fetching error.");
        const errorMessage = error?.response?.data?.detail||'Error Fetching PO Items'
          ? error?.response?.data?.detail
          : 'Error Fetching PO';

          if (error?.response?.status === 401) {
            // Call handleLogout in case of 401 error
            // handleLogout(msalConfig, userName, navigate);
            setsessionExpired(true)
          }
        else{setError(errorMessage);
      }
  } finally {
    setLoading(false); // Hide loading indicator
  }
};

//   function calculateProductTotal(data) {
//     const lineItems = data.invoice_line_items || [];
//     console.log(data, 'dataaa');
//     // Sum the quantity received for each line item
//     const totalQuantityReceived = lineItems.reduce((total, lineItem) => {
//         const quantityReceived = parseFloat(lineItem.gross_total);
//         return total + quantityReceived;
//     }, 0);

//     return totalQuantityReceived;
// }
function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
function handlexit() {
  setFilteredCreatedList(filteredData)
}
// const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(5);

// Avoid a layout jump when reaching the last page with empty rows.
// const emptyRows =
//   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredCreatedList.length) : 0;

// const handleChangePage = (event, newPage) => {
//   setPage(newPage);
// };

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
/// Function to filter historyData based on searchQuery
const filteredData = historyData?.filter((item) => {
  const  { grn_number, purchase_order, total_value_received}= item;
  const searchValue = searchQuery.toLowerCase();
  
  const matchesExternalDocumentId = grn_number.toString().toLowerCase().includes(searchValue);
  
  const matchesPoId =purchase_order.po_id.toString().toLowerCase().includes(searchValue);
  const matchesVALUE =total_value_received.toString().toLowerCase().includes(searchValue);
  return matchesExternalDocumentId || matchesPoId || matchesVALUE;
});

const [statusFilter, setStatusFilter] = useState('All Delivery Status');
const colourStyles = {
  control: (styles,state) => ({ ...styles, backgroundColor: 'white' ,borderColor :"#eaeaea",margiTop:'-1px',
  backgroundColor: state.isDisabled ? 'grey' : 'white',
}),
}
// Define a function to filter the table data based on the selected status
function filterTableData(statusFilter) {
  if (statusFilter === 'All Delivery Status') {
    return filteredData; // Return all items if 'All' is selected
  } else {
    // Determine the boolean value for filtering
    const status = statusFilter === 'Completely Delivered';
    const filter = filteredData.filter(item => 
    {
      let filtered;
      if (item.purchase_order.delivery_completed === status){

        filtered = item.purchase_order.delivery_completed === status
      }
      return filtered
      
    });
    // Filter items based on the selected status
    return filter
  }
}


// Update state with filtered data based on search query
useEffect(() => {
  setFilteredCreatedList(filteredData);

}, [searchQuery, historyData]);

// Function to filter filteredCreatedList based on selected date

function filterByDate(selectedDate) {
  if(!selectedDate){
    setFilteredCreatedList(filteredData);
  }
  else{
    const formattedSelectedDate = formatDate(selectedDate);

    // Filter the data based on the selected date
    const filtered = filteredData.filter(item => {
      const itemDate = formatDate(new Date(item.created));
      return formattedSelectedDate === itemDate;
    });
    setFilteredCreatedList(filtered);
  }
  

  // Update state with the filtered data

}
const filterByDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    setFilteredCreatedList(filteredData);
  } else {
    const formattedStartDate = new Date(startDate).setHours(0, 0, 0, 0);
    const formattedEndDate = new Date(endDate).setHours(23, 59, 59, 999);

    // Filter the data based on the selected date range
    const filtered = filteredData.filter(item => {
      const itemDate = new Date(item.created).setHours(0, 0, 0, 0);
      return itemDate >= formattedStartDate && itemDate <= formattedEndDate;
    });

    setFilteredCreatedList(filtered);
  }
};

// Function to format date
function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

   
 
const convertToCSV = (data) => {
  const headers = [
    'SN', 
    'GRN Number', 
    'PO Number', 
    'Vendor ID', 
    'Delivery Status', 
    'Amount', 
    'Date Created'
  ];
console.log(data,'data')
  const rows = data.map((item, index) => ([
    index + 1,
    item.grn_number,
    item.purchase_order.po_id || '-',
    item.purchase_order.vendor || '-',
    item.purchase_order.delivery_status_text === 'Partially Delivered' ? 'Partial' : 'Complete',
    item.total_value_received,
    convertDateFormat(item.created, 'yyyy-MM-dd', 'dd-MMM-yyyy')
  ]));

  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += headers.join(',') + '\r\n';

  rows.forEach(row => {
    csvContent += row.join(',') + '\r\n';
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'GRN history.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}; 

const [sessionExpired,setsessionExpired]=useState(false)
console.log(filteredData,'')
  const handleShowGRN = (data) =>{
    localStorage.setItem('PostResponse', JSON.stringify(data));
    navigate('/grn', { state: { history: true } });
  }
console.log(filteredCreatedList,'group')
  return (
    <div>
        {loading && <Loader/>}
          {error &&  <Error message={error}/>}
 {!sessionExpired ?
 <>
    <Header />
   <div className="flex main-body">
 <div className="sidebar hidden md:block">
   <Sidebar />
 </div>
 <div className="content md:w-[96%] w-full md:ml-[14%] p-3">
          <PageTitle pagetitle='GRN History' className='mt-3' />
        
          <section class="bg-gray-50  sm:p-5">
    <div class="mx-auto w-full ">
      
        <div class="bg-white flex flex-col relative  sm:rounded-lg overflow-hidden">
            <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                <div class="w-full md:w-1/2">
                    <form class="flex items-center">
                        <label for="simple-search" class="sr-only">Search</label>
                        <div class="relative w-full">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 " fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:outline-none block w-full pl-10 p-2  " placeholder='Search by GRN number, PO number and total amount'
value={searchQuery}
onChange={handleSearchChange}required=""/>
                        </div>
                    </form>
                </div>
                <div className="flex gap-3">
                <DateRangePicker
      format='dd-MM-yyyy'
      placeholder="Filter by Date Range"
      style={{ width: 200, height: 50 }}
      ranges={[]}
  
      placement='bottomEnd'
      preventOverflow
    
      onChange={(range) => {
        if (range) {
          const [startDate, endDate] = range;
          console.log(range, 'range');
          filterByDateRange(startDate, endDate); // Filter data based on selected date range
        }else{handlexit()
          console.log(filteredData,'filtereddat')
        }
      }}
      onClean={handlexit}
    />

<Select
  closeMenuOnSelect={true}
  components={animatedComponents}
  styles={colourStyles}
  options={[
    { value: 'All Delivery Status', label: 'All Delivery Status' },
    { value: 'Partially Delivered', label: 'Partially Delivered' },
    { value: 'Completely Delivered', label: 'Completely Delivered' } // Corrected typo in the value
  ]}
  value={{ value: statusFilter, label: statusFilter }} // Set value to an object with value and label properties
  onChange={(selectedOption) => {
    setStatusFilter(selectedOption.value);
    const filteredData = filterTableData(selectedOption.value); // Call filterTableData with the selected value
    // Handle filtered data as needed, e.g., update state with filtered data
    setFilteredCreatedList(filteredData);
  }}
/>
                </div>
            </div>
            <button className="flex self-end w-[90%]  mx-4 md:w-48 text-white rounded-md mb-4 bg-[#067203] items-center justify-center font-medium text-base px-4 gap-3 py-2"  onClick={() => convertToCSV(filteredCreatedList)}>
                        <DownloadIcon/>
                        <span>Export as csv</span>
                    </button>
            <div class="overflow-x-auto md:w-full w-[90%] mx-auto">
            <table className="w-full min-w-sm">
  <thead className='rounded-lg text-left bg-blue-800 text-sm font-normal'>
    <tr className='font-poppins text-19 font-semibold leading-29'>
      <th scope="col" className="px-4 py-3 text-white">
        SN 
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        GRN Number
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        PO Number
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        Vendor ID
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        Delivery Status
      </th>
      <th scope="col" className="text-right pr-28 py-3 text-white ">
        Amount
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        Date Created
      </th>
      <th scope='col' className='pl-8 py-3 text-white text-center'>
        Action
      </th>
    </tr>
  </thead>
  <tbody>
    {Array.isArray(filteredCreatedList) && filteredCreatedList.length > 0 ? (
      [...filteredCreatedList].reverse().map((row, index) => (
        <tr className="w-full border-b py-2 text-sm last-of-type:border-none" key={index}>
          <td className="px-4 py-3">{page === 1 ? page + index : (((size*page)-size)+1) + index}</td>
          <td className="px-4 py-3">{row.grn_number}</td>
          <td className="px-4 py-3">{row.purchase_order.po_id ? row.purchase_order.po_id : '-'}</td>
          <td className="px-4 py-3">{row.purchase_order.vendor ? row.purchase_order.vendor : '-'}</td>
          <td className="px-4 py-3">
            <span className={`rounded-3xl px-3 py-1 ${
              row.purchase_order.delivery_status_text === 'Completely Delivered' ? 'bg-green-100 text-green-700' :
              row.purchase_order.delivery_status_text === 'Not Delivered' ? 'bg-red-100 text-red-600' :
              'bg-gray-100 text-gray-600'
            }`}>
              {row.purchase_order.delivery_status_text === 'Partially Delivered' ? 'Partial' : 'Complete'}
            </span>
          </td>
          <td className="text-right pr-28 py-3"> {/* Adjusted pr-24 to pr-28 for alignment */}
            <span className="font-semibold">₦</span>
            <NumericFormat 
              value={row.total_value_received}
              displayType="text"
              thousandSeparator={true}
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale 
            />
          </td>
          <td className=" px-4 py-3">{convertDateFormat(row.created, 'yyyy-MM-dd', 'dd-MMM-yyyy')}</td>
          <td className="flex py-3 pl-12 font-semibold text-center"> {/* Adjusted pl-14 to pl-8 for alignment */}
            <button
              onClick={() => handleShowGRN(row)}
              className="border border-blue-700 bg-blue-700 flex gap-2 items-center justify-center h-10 hover:bg-blue-800 text-white px-3 rounded"
            >
              <RemoveRedEyeIcon />
              <span>View</span>
            </button>
          </td>
        </tr>
      ))
    ) : (                   
      <tr className='w-full text-center'>
        <td colSpan="8" className="py-4 text-red-600 font-semibold">
          No record found!
        </td>
      </tr>
    )}
  </tbody>

</table>
<Pagination
totalPages=
{total}
entriesPerPage=
{size}
totalEntries=
{count}/>
{/* <TablePagination
              rowsPerPageOptions={[ 10, 20, { label: 'All', value: -1 }]}
              colSpan={8}
              className=''
              count={filteredCreatedList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />   */}

            </div>

            
        </div>
    </div>
    </section>
    </div>
   </div>
 </>
 :
 <SessionExpired/>
 }
   </div> 
  );
};

export default History;
