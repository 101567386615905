import React,{useState} from 'react'
import Header from '../layout/Header'
import Sidebar from '../layout/Sidebar'
import Logo from '../assets/images/foodclogo.png'
import html2canvas from "html2canvas";
import {ReactComponent as DownloadIcon} from '../assets/images/download.svg'
import {ReactComponent as PrintIcon} from '../assets/images/print.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { jsPDF } from "jspdf";
import { NumericFormat } from 'react-number-format'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios'
import ApprovalStatusImage from '../assets/images/image 12.png'
import Loader from '../components/Loader'
import {ReactComponent as ArrowLeft} from '../assets/images/grey-arrow.svg'

export function convertDateFormat(dateString, inputFormat, outputFormat) {
  const date = new Date(dateString);
  
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }
  
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  
  return date.toLocaleDateString('en-GB', options);
}


const GrnPage = () => {
  const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
    const navigate=useNavigate()
    const [showModal,setShowModal]=useState(false)
    
    const createPDF = async () => {   
      const pdf = new jsPDF("portrait", "pt", "a4"); 

      const data = await html2canvas(document.querySelector("#pdf"));
      const img = data.toDataURL("image/png");  
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`GRN${invoiceData.grn_number}`)
    };
    const print=()=>{
      navigate('/pdf')
    }
    const invoiceData= JSON.parse(localStorage.getItem('PostResponse')) || [];
    const Submitted= JSON.parse(localStorage.getItem('Submitted')) || [];
    const createNewObject = () => {
      // Access the first element of the Submitted array
      const data = Submitted[0];
      
      // Create a new object based on the first element of the Submitted array
      const newData = {
        "GRN": data.GRN,
        "POID": data.POID,
        "recievedGoods": data.recievedGoods,
        "received_date": data.received_date,
        "status": data.status,
        "send_email": data.useNavigate
      };
    
      // Return the new object
      return newData;
      
    }
    
    const [loading,setLoading]=useState(false)
    const chickenConversion = invoiceData.grn_line_items.some(item => 
      item.metadata.total_weight_received !== undefined
  );
  
  console.log(chickenConversion);
  
    
   
    console.log(invoiceData)
  // const deliveryLocation=invoiceData.line_items[0].purchase_order.metadata.ItemShipToLocation.Name
  
  const handleButtonClick = () => {
    // Create a new object without line items
    const newData = createNewObject();
    console.log(newData,'before update')
    // Set send_email to true
    newData.send_email = "true";
    
    // Update the state with the new data
   console.log(newData,'after update')
   appendSignature(newData)
   setShowModal(true) 
    // Send POST request with newData
    // axios.post('your_endpoint_here', newData)
    //   .then(response => {
    //     console.log('POST request successful:', response.data);
    //     // Handle success response here
    //   })
    //   .catch(error => {
    //     console.error('Error sending POST request:', error);
    //     // Handle error response here
    //   });
}
console.log(invoiceData,'invoi')
const calculateTotalTaxAmount = () => {
  const totalTax = invoiceData.grn_line_items.reduce((sum, item) => {
    return sum + (item.tax_value || 0);
  }, 0);

  return totalTax;
};

const appendSignature = async (object) => {
  try {
    setLoading(true); // Assuming setLoading is a state updater function
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/egrn/v1/grn`,
      object
    );

    // Handle response if needed
    console.log('POST request successful:', response.data);
  } catch (error) {
    // Handle error if the request fails
    console.error('Error sending POST request:', error);
  } finally {
    setLoading(false); // Reset loading state after request completion
  }
}
// const mailStatus=object.send_email?object.send_email:false
function calculateProductTotal() {
   
  const lineItems = invoiceData.grn_line_items || [];
  // Calculate product for each line item and sum them
  const productTotal = lineItems.reduce((total, lineItem) => {
    const unitPrice = lineItem.purchase_order_line_item.unit_price;
    const quantityReceived = parseFloat(lineItem.quantity_received);
    return total + (unitPrice * quantityReceived);
  }, 0);

  return productTotal;
}

const check= invoiceData.grn_line_items.some(item=>item.extra_fields)
console.log(check,'checked')
function removeUnderscores(str) {
  return str.replace(/_/g, ' ');
}
  return (
    <div>
           <Header />
          <div className="flex main-body">
        <div className="sidebar hidden md:block">
          <Sidebar />
        </div>
        <div className="content md:w-[96%] w-full md:ml-[14%] p-3">
        <Modal open={showModal} onClose={() => setShowModal(false)} >
    <Box sx={style}>
    <div className='flex items-center flex-col justify-center'>
      <img src={ApprovalStatusImage} alt="" />
      <div className=''>
        GRN created succesfully!
      </div>
      <button
        className='my-2 bg-green-600 text-white py-2  rounded-md hover:bg-green-700'
        onClick={() => { navigate('/history') }}
      >
        Okay
      </button>
    </div>
  </Box>
  </Modal>
           <div className=" 
            flex justify-between  mb-4 text-sm text-[#545454] bg-white py-3 px-4">
   <button
        className='flex items-center justify-center gap-2 '
        onClick={() => { navigate('/history') }}
      >
       <ArrowLeft className=''/> History
      </button>
            <div className='flex gap-4'>
            <button className='flex items-center gap-2 text-sm font-normal' onClick={print}><PrintIcon/><span>Print</span></button>
          <button className='flex items-center gap-2 text-sm font-normal' onClick={createPDF}><DownloadIcon/><span>Download</span></button>
          </div>
            </div>
         
        
            <div className='bg-white  w-full  box-shadow' >
              <div id='pdf' className='bg-white w-full'>
            <div className=" items-center flex flex-col md:flex-row bg-white p-4">
                <div className="flex flex-col items-center">
                    <img className=' h-24 md:h-32' src={Logo} alt="" />
                    <p className='font-semibold text-lg md:text-2xl -mt-8 text-[#2957A4]'>Food Concepts</p>
                </div>
                <div className="flex flex-col items-center self-center justify-center">
                    <p className='font-semibold text-xl text-center m:text-3xl  text-blue-800 md:ml-16 my-2 md:my-4'>Food Concepts (Chicken Republic)</p>
                    <p className='text-[#474646] font-normal text-center text-base md:ml-16'><span className='underline  md:pb-3'>2, Ilupeju Bye Pass, Ilupeju, Lagos, Nigeria </span>Tel: +234 (0) 809 016 5972 | +234 (0) 809 016 5973</p>
                </div>
      
            </div>
            <div className="flex flex-col md:flex-row justify-between md:items-center text-[#545454] text-base p-4">
      <ul className="m-0 p-0 justify-end text-sm">
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Received from:</span>
    <span className='text-justify'>{invoiceData.purchase_order.Supplier.SupplierName[0].FormattedName}</span>
  </li>
  <li className='flex gap-6 '>
    <span className='flex'>Store Name:</span>
    <span className=''>{invoiceData.store.store_name}</span>
    {/* <span className='text-justify'>{deliveryLocation}</span> */}
    
  </li>
</ul>
{/**
 * {
    "message": "GRN Created",
    "data": {
        "id": 48,
        "purchase_order": 4,
        "store": 1,
        "grn_number": 255166,
        "received_date": "2024-03-14",
        "line_items": [
            {
                "id": 31,
                "grn": 48,
                "purchase_order_line_item": 14,
                "quantity_received": "40.000"
            }
        ]
    },
    "status": "success"
}
 */}
        <div className='flex flex-col'>
        <div className="md:text-right text-[#5B5B5B] font-normal text-center text-xl my-2">Goods Reciept Note</div>
        <div className="border border-gray-200 font-normal flex text-sm flex-col">
          <div className="border-b border-gray-200  flex  p-2 m-0">
            <span>Date</span>
            <span className=" text-right flex-grow ">{convertDateFormat(invoiceData.created, 'yyyy-MM-dd', 'dd-MMM-yyyy')}</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>Purchase Order Number</span>
            <span className="text-right flex-grow ">{invoiceData.purchase_order.po_id}</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>GRN Number</span>
            <span className="text-right flex-grow ">{invoiceData.grn_number}</span>
          </div>
       
          
        </div>
        </div>
     
      </div>

      <section class="bg-gray-50 ">
    <div class="mx-auto ">
      
        <div class="bg-white  relative  overflow-hidden">
            
            <div class="overflow-x-auto">
            <table className='min-w-full text-gray-900 md:table shadow'>
                          <thead className='rounded-lg text-left bg-blue-800 text-sm font-normal text-white'>
                          <tr className='font-poppins text-19 font-semibold leading-29'>
                              <td className=" py-3 font-normal px-4">SN</td>
                              <td className=" py-3 font-normal px-4 ">Description</td>
                              <td className=" py-3 font-normal px-4 ">Product Code</td>
                              <td className=" py-3 font-normal px-4 ">UoM</td>
                              {/* <td className=" py-3 font-normal pl-4">UoM</td> */}
                              {/* <td className="  py-3 font-normal px-4 " >Delivery Status</td> */}
                              <td className="  py-3 font-normal px-4 text-right pr-5" >QTY Received</td>
                              <td className="  py-3 font-normal px-4 text-right pr-5" >QTY Outstanding</td>
                              {chickenConversion && <td className="  py-3 font-normal px-4 text-right pr-5" >Pieces Received</td>}
                              
                              <td className="  py-3 font-normal px-4 text-right">Unit Price </td>
                              <td className="  py-3 font-normal  text-right ">Amount</td>
                              <td className="  py-3 font-normal text-right pr-4">Tax</td>
                              {check &&
        <td class="pl-4 py-3 font-normal text-white ">
        Additional Fields
   </td>
        }
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            {invoiceData.grn_line_items.length === 0 ? (
                              <tr className=' py-2'>
                                No line item attached to this GRN
                              </tr>
                            ): ( invoiceData.grn_line_items.map((item, index) => (
                              <tr key={index} className={`text-sm  h-12 border-b border-gray-300 `}>
                                <td className='px-4 py-3'>{index+1}</td>
                                <td className=' py-3 px-4'>{item.purchase_order_line_item.product_name ? item.purchase_order_line_item.product_name: 'N/A'}</td>
                                <td className=' py-3 px-4'>{item.purchase_order_line_item.metadata.ProductID}</td>
                                <td className=' py-3 px-4'>    {item.purchase_order_line_item
  .metadata.ListUnitPriceBaseUnitCode}</td>
                                {/* <td className=' py-3 pl-4'>{item.purchase_order_line_item.metadata.ListUnitPriceBaseUnitCode?item.purchase_order_line_item
.metadata.ListUnitPriceBaseUnitCode: '-'}</td> */}
 {/* <td className='px-4 py-3'>
          <span className={`rounded-3xl px-3 py-1 ${
            item.purchase_order_line_item
.delivery_status_text === 'Completely Delivered' ? 'bg-green-100 text-green-700' :
            item.purchase_order_line_item
.delivery_status_text === 'Not Delivered' ? 'bg-red-100 text-red-600' :
            'bg-gray-100 text-gray-600'
          }`}>
            {item.purchase_order_line_item
.delivery_status_text==='Partially Delivered'?'Partial':'Complete'}
          </span>
        </td> */}
                                <td className="text-right pr-8 py-3"><NumericFormat value={item.quantity_received? item.quantity_received: 'N/A'} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /> 
     </td>
                                <td className="text-right pr-8  py-3"><NumericFormat value={item.purchase_order_line_item.delivery_outstanding_quantity} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                             
     </td>  
   
     {chickenConversion &&  (item.metadata.total_pieces_received? <td className="text-right pr-6  py-3"><NumericFormat value={item.metadata.total_pieces_received}displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                                
                                </td>: <td className='text-right pr-6  py-3'>NA</td> )}
                                <td className="  py-3 pr-4 text-right "><NumericFormat value={item.purchase_order_line_item.unit_price ? item.purchase_order_line_item.unit_price : 0} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
                                <td className='  py-3 text-right '>{<NumericFormat value={parseFloat(item.quantity_received) * parseFloat(item.purchase_order_line_item.unit_price)
} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</td>
<td className="  py-3 text-right pr-2"><NumericFormat value={item.tax_value ? item.tax_value : 0} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
{/* {check && (
  (item.extra_fields && item.extra_fields.length > 0) ? (
    <td className="px-2 py-3 pl-4">
      <ul>
        {item.extra_fields.map((subItem, index) => (
          <li key={index} className="flex gap-2 items-center py-1">
            <span>{removeUnderscores(subItem.name.charAt(0).toUpperCase() + subItem.name.slice(1))} ({subItem.value ? subItem.value : 0})</span>
          </li>
        ))}
      </ul>
    </td>
  ) : (
    <td className="text-center">NA</td>
  )
)} */}

                              </tr>                     
                            )))}
                                                   <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
     
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      <td></td>
      <td></td>
      <td className="   py-3 " >Sub Total</td>
      <td className="text-right pr-2   py-3 ">  <NumericFormat value={calculateProductTotal()} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
    <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
     
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      <td></td>
      <td></td>
      <td className="pr-4  py-3 " >Total Tax </td>
      <td className="text-right pr-2  py-3 ">  <NumericFormat value={calculateTotalTaxAmount()} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
     
                                                   <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      <td></td>
      <td></td>
      <td className="  pr-4 py-3 " >Total </td>
      <td className="text-right pr-2  py-3 ">  <NumericFormat value={(calculateProductTotal()+calculateTotalTaxAmount())} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
          
                          </tbody>
                        </table>
            </div>
           
        </div>
    </div>
    </section>
                        </div>    
                                                  
      </div>
    


{loading&&<Loader/>}
      
        </div>
        </div>
    </div>
  )
}

export default GrnPage
