import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import { DatePicker } from 'rsuite';
import { format } from 'date-fns';
import axios from "axios";
import { ReactComponent as Vector6 } from '../assets/images/Vector 6.svg';
import { ReactComponent as DownloadIcon } from '../assets/images/downloadIcon.svg';
import Loader from "../components/Loader";
import Error from "../components/Error";
import { NumericFormat } from "react-number-format";

const Report = () => {
  const token = localStorage.getItem('accessToken');
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  const [productCode, setProductCode] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeBrand, setActiveBrand] = useState(localStorage.getItem('brand') || 'Chicken Republic');

  useEffect(() => {
    localStorage.setItem('brand', activeBrand);
  }, [activeBrand]);

  const handleBrandClick = (brand) => {
    setActiveBrand(brand);
  };

  const convertDate = (dateString) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const [year, month, day] = dateString.split("-");
    const monthName = months[parseInt(month) - 1];
    const dayInt = parseInt(day);
    let suffix = "th";
    if (dayInt === 1 || dayInt === 21 || dayInt === 31) {
      suffix = "st";
    } else if (dayInt === 2 || dayInt === 22) {
      suffix = "nd";
    } else if (dayInt === 3 || dayInt === 23) {
      suffix = "rd";
    }
    return `${dayInt}${suffix} ${monthName}, ${year}`;
  };

  const ItemWAC = async () => {
    setLoading(true);
    setItemDetails([]);
    const url = `${process.env.REACT_APP_BASE_URL}/egrn/v1/wac?product_id=${productCode}&start_date=${startDate&&format(startDate, 'yyyy-MM-dd')}&end_date=${endDate&&format(endDate, 'yyyy-MM-dd')}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setItemDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError(error.response ? error.response.data.message : error.message);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const convertToCSV = () => {
    const headers = [
      'Date', 
      'Product',
      'Weight', 
      'Store',
      'Price per Kg', 
      'Total Amount', 
      'Average Price'
    ];
  
    const rows = itemDetails?.items.map((item, index) => ([
      convertDate(item.date_received),
      itemDetails.product_name || '-',
      item.quantity_received ? item.quantity_received : itemDetails.product_id || '-',
      item?.purchase_order_line_item.ItemShipToLocation.Name,
      item.purchase_order_line_item?.unit_price,
      item?.value_received,
      itemDetails.average_price
    ]));
  
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\r\n';
  
    rows.forEach(row => {
      csvContent += row.join(',') + '\r\n';
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${productCode} WAC.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header />
      <div className="flex main-body">
        <div className="sidebar hidden md:block">
          <Sidebar />
        </div>
        <div className="content md:w-[86%] w-full md:ml-[14%] p-3 ml-0">
          {loading && <Loader />}
          {error && (
            <Error show={showError} message={error} handleShow={() => setShowError(!showError)} />
          )}
          {/* <div className="md:flex hidden text-blue text-base gap-2 text-blue-900">
            <div
              className={`items-center flex py-2 w-56 cursor-pointer border border-gray-200 rounded-md justify-center ${activeBrand === 'Chicken Republic' ? 'bg-[#034EA2] text-white' : 'bg-white'}`}
              onClick={() => handleBrandClick('Chicken Republic')}
            >
              Chicken Republic
            </div>
            <div
              className={`items-center flex py-2 w-56 cursor-pointer border border-gray-200 rounded-md justify-center ${activeBrand === 'Pie Express' ? 'bg-[#034EA2] text-white' : 'bg-white'}`}
              onClick={() => handleBrandClick('Pie Express')}
            >
              Pie Express
            </div>
            <div
              className={`items-center flex py-2 w-56 cursor-pointer border border-gray-200 rounded-md justify-center ${activeBrand === 'Chop Box' ? 'bg-[#034EA2] text-white' : 'bg-white'}`}
              onClick={() => handleBrandClick('Chop Box')}
            >
              Chop Box
            </div>
          </div> */}
          <div className="flex flex-col mb-4">
            {/* <div className="self-end hidden md:flex text-blue text-base font-medium text-blue-900 border bg-white border-gray-200 gap-3 p-2">
              <div>Brand:</div>
              <div className="flex">
                <div>{activeBrand}</div>
                <Vector6 className="mt-2 ml-1" />
              </div> 
            </div> */}
            <div className="flex md:flex-row flex-col items-center gap-3 shadow my-4 p-4 bg-white rounded-lg">
              <div className="flex flex-col justify-center w-full md:w-[30%]">
                <label htmlFor="product_name" className="block text-sm text-[#545454] dark:text-white">Product Name</label>
                <input 
                  type="text" 
                  id="product_name" 
                  className="bg-white border border-gray-200 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5" 
                  placeholder="Product Code/Name" 
                  required 
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </div>
              <div className="flex justify-between gap-3 mt-2">
                <div className="flex flex-col justify-center w-[50%] md:w-[50%]">
                  <span className="text-sm text-[#545454]">Start Date</span>
                  <DatePicker 
                    oneTap 
                    format='dd-MM-yyyy' 
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    wrapperClassName="datePicker"
                  />
                </div>
                <div className="flex flex-col justify-center w-[50%] md:w-[50%]">
                  <span className="text-sm text-[#545454]">End Date</span>
                  <DatePicker 
                    oneTap 
                    format='dd-MM-yyyy' 
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    wrapperClassName="datePicker"
                  />
                </div>
              </div>
              <button className="bg-blue-800 text-white py-2 px-4 rounded-lg md:mt-[1.4rem] w-full md:w-[80px]" onClick={ItemWAC}>Search</button>
            </div>
           {itemDetails.items && itemDetails.items.length>0 &&
            <div className="self-end md:w-48 w-full text-white rounded-md my-4 bg-[#067203] items-center justify-center text-base flex gap-3 py-2 cursor-pointer" onClick={convertToCSV}>
            <DownloadIcon />
            <span>Export as csv</span>
          </div>
           }
          </div>
          <div className="overflow-x-auto shadow rounded-lg bg-white">
      {itemDetails.items && itemDetails.items.length > 0 && (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3">Date</th>
              <th scope="col" className="px-4 py-3">Product</th>
              <th scope="col" className="px-4 text-right py-3">Weight</th>
              <th scope="col" className="px-4 text-right py-3">Price per kg</th>
              <th scope="col" className="md:pl-20 py-3">Store</th>
              <th scope="col" className="pr-8 text-right py-3">Total Price</th>
              <th scope="col" className="text-right pr-8 py-3">GRN Total Weight</th>
            </tr>
          </thead>
          <tbody>
            {itemDetails.items.map((item, index) => (
              <tr key={index}>
                <td className="px-4 py-3 font-medium text-gray-900 text-sm">
                  {item.date_received ? convertDate(item.date_received) : itemDetails.start_date}
                </td>
                <td className="px-4 py-3 text-gray-900 whitespace-nowrap">
                  {itemDetails.product_name ? itemDetails.product_name : itemDetails.product_id}
                </td>
                <td className="px-4 text-right py-3 text-gray-900 whitespace-nowrap">
                  <NumericFormat value={item.quantity_received || itemDetails.product_id} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                </td>
                <td className="px-4 text-right py-3 text-gray-900 whitespace-nowrap">
                  <NumericFormat value={item.purchase_order_line_item?.unit_price || itemDetails.product_id} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                </td>
                <td className="md:pl-20 pr-4 md:pr-0 py-3 text-gray-900 whitespace-nowrap">
                  {item.purchase_order_line_item?.ItemShipToLocation?.Name}
                </td>
                <td className="pr-8 text-right py-3 text-gray-900 whitespace-nowrap">
                  <NumericFormat value={item.value_received || itemDetails.product_id} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                </td>
                <td className="pr-8 py-3 text-right text-gray-900 whitespace-nowrap">
                  <NumericFormat value={itemDetails.average_price} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) }
      </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
