import React, { useState, useEffect } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import makeAnimated from 'react-select/animated';
import Loader from "../components/Loader";
import axios from "axios";
import { format } from 'date-fns';
import { DatePicker } from 'rsuite';
import { ReactComponent as WeightIcon } from '../assets/images/weightGroup.svg';
import { ReactComponent as PriceIcon } from '../assets/images/Price icon.svg';
import { ReactComponent as Vector6 } from '../assets/images/Vector 6.svg';
import { ReactComponent as WeightdAvg } from '../assets/images/weiighted_avrg_icon.svg';
import { AgChartsReact } from "ag-charts-react";
import SessionExpired from '../assets/images/session expired.png'
import Error from "../components/Error";
import { Link } from "react-router-dom";
const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [productCode, setProductCode] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeBrand, setActiveBrand] = useState(localStorage.getItem('brand') || 'Chicken Republic');
  const [sessionExpired,setsessionExpired]=useState(false)
  const backgroundColors = ['#FED3DD', '#D3FED3', '#EDFFFF', '#FDD3FE'];
  
  useEffect(() => {
    localStorage.setItem('brand', activeBrand);
  }, [activeBrand]);

  useEffect(() => {
    ItemWAC();
  }, []);

  const handleBrandClick = (brand) => {
    setActiveBrand(brand);
  };

  const ItemWAC = async () => {
    setLoading(true);
    setItemDetails([]);
    
    const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
    const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';
    const url = `${process.env.REACT_APP_BASE_URL}/egrn/v1/wac?product_id=${productCode}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
  
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
  
      // Filter items to include only those with "chicken" in the product name
      const filteredItems = response.data.data.filter(item =>
        item.product_name.toLowerCase().includes("chicken")
      );
  
      setItemDetails(filteredItems);
    } catch (error) {
      console.error("Error fetching search results:", error);
     
   console.log(error.respons,'respponse')
      if (error?.response?.status === 401) {
        setsessionExpired(true);
      }else{
        setError(error.message || "An unknown error occurred");
        setShowError(true);
      }
    
    } finally {
      setLoading(false);
    }
  };
  


  const [options, setOptions] = useState({
    data: [],
    series: [{ type: "bar", xKey: 'index', yKey: 'unit_price' }],
  });

  useEffect(() => {
    if (itemDetails.length > 0) {
      const chartData = itemDetails.map((item, index) => ({
        index: item.product_name,
        unitPrice: parseFloat(item.average_price)
      }));
      setOptions({
        data: chartData,
        series: [{ type: "bar", xKey: 'index', yKey: 'unitPrice' }],
      });
    }
  }, [itemDetails]);

  return (
    <div>
         {loading && <Loader />}
          {error && (
            <Error show={showError} message={error} handleShow={() => setShowError(!showError)} />
          )}
    {!sessionExpired ?
    <>
    <Header />
      <div className="flex main-body">
        <div className="sidebar hidden md:block">
          <Sidebar />
        </div>
        <div className="content md:w-[86%] w-full md:ml-[14%] p-3 ml-0">
         
          {/* <div className="text-blue text-base gap-2 text-blue-900 hidden md:flex">
            {['Chicken Republic', 'Pie Express', 'Chop Box'].map((brand, index) => (
              <div
                key={index}
                className={`items-center flex py-2 w-56 cursor-pointer border border-gray-200 rounded-md justify-center ${activeBrand === brand ? 'bg-[#034EA2] text-white' : 'bg-white'}`}
                onClick={() => handleBrandClick(brand)}
              >
                {brand}
              </div>
            ))}
          </div> */}
          <div className="flex-col my-4">
            {/* <div className="hidden md:flex self-end text-blue text-base font-medium text-blue-900 border bg-white border-gray-200 gap-3 p-2">
              <div>Brand:</div>
              <div className="flex">
                <div>{activeBrand}</div>
                <Vector6 className="mt-2 ml-1" />
              </div>
            </div> */}
            {/* Add search form here */}
          </div>
          {itemDetails.length > 0 && (
            <div className="flex flex-col md:flex-row py-3 gap-3 ">
              <div className="lg:w-[40%] w-full ">
                <div className="grid grid-cols-2 gap-3 ">
                  <div className="shadow h-24 rounded-md flex flex-col p-3 bg-white">
                    <div className="flex flex-col ">
                      <div className="self-end"> <WeightIcon /></div>
                      <div className="text-base text-[#545454] -mt-8">Total Weight</div>
                      <div className="text-xl font-semibold text-[#000] mt-1">100,000</div>
                    </div>
                    <div className="self-end italic text-[#545454] text-sm mt-auto">Year to date</div>
                  </div>
                  <div className="shadow h-24 rounded-md flex flex-col p-3 bg-white">
                    <div className="flex flex-col">
                      <div className="self-end"> <PriceIcon /></div>
                      <div className="text-base text-[#545454] -mt-8">WAC</div>
                      <div className="text-xl font-semibold text-[#000] mt-1">100,000</div>
                    </div>
                    <div className="self-end italic text-[#545454] text-sm mt-auto">Year to date</div>
                  </div>
                </div>
                <div className="shadow p-4 rounded-md flex flex-col bg-white my-3">
                  <div className="text-base font-medium text-[#3f3e3e]">Item Weighted Average Cost</div>
                  <div className="self-end text-[#3f3e3e] text-sm italic font-normal mt-2">Year to date</div>
                  {itemDetails.map((item, index) => (
                    <div
                      key={index}
                      className="flex gap-3 p-2 rounded-md my-2"
                      style={{ backgroundColor: backgroundColors[index % backgroundColors.length] }}
                    >
                      <div className="text-base text-[#3f3e3e]">{index + 1}</div>
                      <div className="text-base text-[#3f3e3e]">{item.product_name}</div>
                      <div className="text-lg font-semibold ml-auto">{item.average_price}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="lg:w-[60%] w-full px-4 py-2 shadow rounded-lg bg-white">
                <div className="text-base font-medium text-[#3f3e3e] text-center mb-2">Weighted Average Cost Trend- Year To Date</div>
                <div style={{ height: "400px", marginTop: "8px", width: '90%', margin: '0 auto' }}>
                  <AgChartsReact options={options} />
                </div>
              </div>
            </div>
          ) }
        </div>
      </div></>:
      <section class="bg-white min-h-screen flex items-center">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-sm text-center flex flex-col justify-center items-center">
              
              <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Session Expired</p>
              <img src={SessionExpired} alt="" className="w-24 h-24 object-cover"/>
              <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Click the button bellow to refresh </p>
              <Link to='/home'className="inline-flex text-white bg-blue-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 ">Back to Homepage</Link>
          </div>   
      </div>
  </section>}
      
    </div>
  );
};

export default HomePage;
