
import './App.css';
import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";
import CreateGRN from './views/CreateGRN';
import History from './views/History';
import GrnPage from './views/GrnPage';
import Pdf from './views/Pdf';
import EditGRN from './views/EditGRN';
import SignIn from './views/SingIn';
import Report from './views/Report';
import HomePage from './views/HomPage';
function App() {
  return (
    <Router>
      <Routes>

         <Route path='/home' element={<CreateGRN/>} /> 
        <Route path='/' element={<SignIn/>} />
        <Route path='/history' element={<History/>} />
        <Route path='/grn' element={<GrnPage/>} />
        <Route path='/pdf' element={<Pdf/>} />
        <Route path='/edit' element={<EditGRN/>} />
        <Route path='/dashboard' element={<HomePage/>} /> 
        <Route path='/report' element={<Report/>} />
      </Routes>
    </Router>
    
    
  );
}

export default App;
