import React, { useState, useEffect } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import Error from "../components/Error";
import PageTitle from "../components/PageTitle";
import { ReactComponent as SearchIcon } from "../assets/images/search.svg";
import Modal from "@mui/material/Modal";
import { ReactComponent as ArrowLeft } from "../assets/images/arrowLeft.svg";
import { useNavigate, useLocation } from "react-router-dom";
import ApprovalStatusImage from "../assets/images/image 12.png";
import Box from "@mui/material/Box";
import InformationIcon from "../assets/images/infoicon.png";
import { NumericFormat } from "react-number-format";
import Logo from "../assets/images/foodclogo.png";
import axios from "axios";
import Loader from "../components/Loader";
import { handleLogout } from "../helper/Logout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SessionExpired from "../components/sessionExpired";
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import DirectionsIcon from '@mui/icons-material/Directions';
// import { FormControl } from '@mui/material';

const CreateGRN = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location?.state?.item;
  const show = location?.state?.showValue || false;

  const [showErrow, setShowError] = useState(false);
  console.log(receivedData, "receivedData");
  const [selectedPO, setselectedPO] = useState(null);
  const [receivedGoodsList, setReceivedGoodsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const [responseError, setResponseError] = useState(false);
  const [graySave, setGraySave] = useState(true);
  const [vendor, setVendor] = useState(null);
  const [disableAutocomplete, setDisableAutocomplete] = useState(true);
  const [POLineItems, setPOLineItems] = useState([]);
  const GRN = POLineItems.data;
  const token = localStorage.getItem("accessToken");
  const [totalReceivedGoods, setTotalReceivedGoods] = useState(0);
  const [totalitems, setTotalItems] = useState(GRN ? GRN.data.Item : []);
  const [discounts, setDiscounts] = useState(
    totalitems.map(() => ({ type: "unit", value: "" }))
  );
  const [completeDelivery, setCompleteDelivery] = useState(false);
  console.log(totalitems, "0th total item");
  const deliveryLocation = totalitems[0]?.metadata?.ItemShipToLocation?.Name;
  const [isChecked, setIsChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(4);
  const [receivedValue, setReceivedValue] = useState(
    Array(totalitems).fill("")
  );
  const [recievedItemAmount, setRecievedItemAmont] = useState(null);
  const [showValue, setShowValue] = useState(false);
  const userName = localStorage.getItem("username");
  const msalConfig = JSON.parse(localStorage.getItem("msalConfig"));
  function getFormattedDate() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    let suffix = "";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }
  const notempty = totalitems.some((value) => value.delivery_outstanding_quantity !== 0);
  const Extrafiled = totalitems.some((value) => value.extra_fields?.length > 0);
  console.log(Extrafiled, "extraaa");
  const hasValue =
    !receivedValue.some((value) => value !== "") ||
    (completeDelivery && notempty);

  console.log(hasValue, "notnull");
  const [showVendorSearchButton, setShowVendorSearchButton] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [error, setError] = useState(null);
  const [vendorID, setVendorID] = useState(null);
  const [searcher, setsearcher] = useState("");
  const [invoiceData, setInvoiceData] = useState("");
  const [isConfirm, setIsConfirm] = useState(true);
  const [sessionExpired, setsessionExpired] = useState(false);
  const [grnGenerated, setGrnGenerated] = useState(null);
  const handleVendorSearchBoxChange = (event) => {
    setCompleteDelivery(false);
    setShowValue(false);
    setErrors([]);
    setSearchTerm(event.target.value);
    // setShowVendorSearchButton(true)
  };

  // useEffect(() => {
  //   const fetchLineItems = async () => {
  //     const PO=receivedData.PONumber
  //     console.log(PO)
  //     try {
  //       setLoading(true)
  //       const response = await axios.get(`https://20.101.63.100/egrn/v1/purchaseorders/${PO}`);
  //       setPOLineItems(response.data);
  //       console.log(response.data.data.Item,'respo Item')
  //       console.log(totalitems,'workeddddd')
  //     } catch (error) {
  //         console.error('Error fetching search results:', error);

  //         setError(error);
  //     } finally {
  //         setLoading(false);
  //     }
  //   };
  //    const isLoggedin = localStorage.getItem('isLoggedin')
  //    console.log(!isLoggedin,'is not logged in status')
  // if(!isLoggedin){
  //    navigate('/')
  // }
  //   if (show) {
  //     fetchLineItems();
  //   }

  // }, [show]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDiscountTypeChange = (event) => {
    const selectedType = event.target.value;

    // Update the item's discount type
    const updatedItems = items;
    console.log(selectedIndex, "selecteee");
    console.log(updatedItems, "selecteee");
    console.log(updatedItems[selectedIndex], "seleee");
    updatedItems[selectedItemIndex].discountType = selectedType;
    setItems(updatedItems);
  };

  // Function to handle change in discount value
  const handleDiscountValueChange = (event) => {
    const newValue = event.target.value;

    // Update the item's discount value
    const updatedItems = [...items];
    console.log();
    updatedItems[selectedItemIndex].discountValue = newValue;
    setItems(updatedItems);
  };

  const calculateDiscountedAmount = (item, index) => {
    const discount = discounts[index];
    let discountedAmount =
      item.metadata.ListUnitPriceAmount * (receivedValue[index] || 0);

    if (discount?.type === "unit") {
      discountedAmount -= discount.value * (receivedValue[index] || 0);
    } else if (discount?.type === "percentage") {
      discountedAmount -= (discount?.value / 100) * discountedAmount;
    } else if (discount?.type === "value") {
      discountedAmount -= discount?.value;
    }

    return Math.max(0, discountedAmount);
  };
  useEffect(() => {
    const filtered = receivedGoodsList.filter(
      (item) => item !== undefined && item?.QuantityReceived > 0
    );
    setResults(filtered);
  }, [receivedGoodsList]);
  

  const calculateTotalPOValue = () => {
    let total = 0;
    totalitems.forEach((item) => {
      total +=
        parseFloat(item?.metadata.ListUnitPriceAmount) *
        parseFloat(item.delivery_outstanding_quantity);
    });
    return total.toFixed(3);
  };

  const GeneratUniqueNumber = () => {
    setGrnGenerated(generateRandomSixDigitNumber());
  };

  // const calculateTotalAmountInnerTable = () => {
  //     let total = 0;
  //     totalitems.forEach((item, index) => {
  //       const quantity = parseFloat(receivedValue[index]) || 0;
  //       total += quantity * parseFloat(item?.metadata.ListUnitPriceAmount);
  //     });
  //     return total.toFixed(3);
  // };
  const [errors, setErrors] = useState(Array(invoiceData.length).fill(null));
//   const handleQuantityInputChange = (index, value) => {
//     const newReceivedValue = [...receivedValue];
//     if (isNaN(value) ){
//       console.log('I ork')
//       newReceivedValue[index] = 0;
//       setReceivedValue(newReceivedValue);
//     } else {
//       const inputValue = value.trim(); // Remove leading/trailing spaces
//     console.log(inputValue, 'inpuyt')
//     setClickedIndex(null);
  
//     // Regular expression to check if input value is a valid number
//     if (/^\d*\.?\d*$/.test(inputValue)) {
//         const value = parseFloat(inputValue); // Parse input value as float
//         const item = totalitems[index]; // Get the item
//         const outstandingQuantity = item.delivery_outstanding_quantity; // Get the Outstanding QTY
  
//         if (value > outstandingQuantity) {
//             // If input value exceeds Outstanding QTY, raise an error for the specific index
//             const newErrors = [...errors];
//             newErrors[index] = 'Value greater than Outstanding QTY';
//             setErrors(newErrors);
//             console.log(errors,'errors')
//             const newReceivedValue = [...receivedValue];
//             // newReceivedValue[index] = "";
//             setReceivedValue(newReceivedValue);
//         } else {
//             // Input value is valid and does not exceed Outstanding QTY, proceed with updating state
//             const updatedList = [...receivedGoodsList];
//             const newReceivedValue = [...receivedValue];
//             newReceivedValue[index] = value; // Update value with trimmed input
//             setReceivedValue(newReceivedValue);
  
//             const amount = value * parseFloat(item.metadata.ListUnitPriceAmount);
//             updatedList[index] = {
//                 ...item,
//                 QuantityReceived: value,
//                 Amount: amount,
//             };
//             setReceivedGoodsList(updatedList);
  
//             // Clear error if input is valid
//             const newErrors = [...errors];
//             newErrors[index] = 'null';
//             setErrors(newErrors);
//         }
//     } else if (isNaN(inputValue) || inputValue === "" ) {
//         // Handle invalid input value
//         const newErrors = [...errors];
//         newErrors[index] = "Invalid input value";
//         setErrors(newErrors);
  
//         // Reset received value to the current valid state
        
//         newReceivedValue[index] = "";
//         setReceivedValue(newReceivedValue);
//     }
//     }
    
// };
// const handleQuantityInputChange = (index, event) => {
//   const inputValue = parseFloat(event.target.value.trim());
//   setClickedIndex(null)
//   if (/^\d*\.?\d*$/.test(inputValue)) {
//     const value = parseFloat(inputValue);
//     const item = totalitems[index];
//     const outstandingQuantity = item.delivery_outstanding_quantity;

//     if (value > outstandingQuantity) {
//       const newErrors = [...errors];
//       newErrors[index] = 'Value greater than Outstanding QTY';
//       setErrors(newErrors);
//     } else {
//       const updatedList = [...receivedGoodsList];
//       const newReceivedValue = [...receivedValue];
//       const handleQuantityInputChange = (index, event) => {
//   const inputValue = event.target.value.trim();
//   setClickedIndex(null)
//   if (/^\d*\.?\d*$/.test(inputValue)) {
//     const value = parseFloat(inputValue);
//     const item = totalitems[index];
//     const outstandingQuantity = item.delivery_outstanding_quantity;

//     if (value > outstandingQuantity) {
//       const newErrors = [...errors];
//       newErrors[index] = 'Value greater than Outstanding QTY';
//       setErrors(newErrors);
//     } else {
//       const updatedList = [...receivedGoodsList];
//       const newReceivedValue = [...receivedValue];
      
//       newReceivedValue[index] = inputValue;
//       setReceivedValue(newReceivedValue);

//       const amount = value * parseFloat(item.ListUnitPriceAmount);
//       updatedList[index] = { ...item, QuantityReceived: value, Amount: amount };
//       setReceivedGoodsList(updatedList);
//       const newErrors = [...errors];
//       newErrors[index] = null;
//       setErrors(newErrors);
//     }
//   } else {
//     const newReceivedValue = [...receivedValue];
//     newReceivedValue[index] = '0';
//     setReceivedValue(newReceivedValue);
//     const newErrors = [...errors];
//     // newErrors[index] = 'Invalid input value';
//     // setErrors(newErrors);
//   }
// };
//       newReceivedValue[index] = inputValue;
//       setReceivedValue(newReceivedValue);

//       const amount = value * parseFloat(item.ListUnitPriceAmount);
//       updatedList[index] = { ...item, QuantityReceived: value, Amount: amount };
//       setReceivedGoodsList(updatedList);
//       const newErrors = [...errors];
//       newErrors[index] = null;
//       setErrors(newErrors);
//     }
//   } else {
//     const newReceivedValue = [...receivedValue];
//     newReceivedValue[index] = '';
//     setReceivedValue(newReceivedValue);
//     // const newErrors = [...errors];
//     // newErrors[index] = 'Invalid input value';
//     // setErrors(newErrors);
//   }
// };
// function formatToTwoDecimalPlaces(value) {
//   if (isNaN(value)) {
//     return '0.00';
//   }
//   return parseFloat(value).toFixed(2);
// }
const formatNumber = (value) => {
  return value.toLocaleString('en-US', { maximumFractionDigits: 3 });
};

const handleQuantityInputChange = (index, event) => {
  const inputValue = event.target.value.trim().replace(/,/g, ''); // Remove leading/trailing spaces and commas
  setClickedIndex(null);

  // Check if input value is a valid number
  if (/^\d*\.?\d*$/.test(inputValue)) {
    const value = parseFloat(inputValue) || ''; // Parse input value as float
    const item = totalitems[index]; // Get the item
    const outstandingQuantity = parseFloat(item.delivery_outstanding_quantity); // Get the Outstanding QTY

    if (value > outstandingQuantity) {
      // If input value exceeds Outstanding QTY, raise an error for the specific index
      const newErrors = [...errors];
      newErrors[index] = 'Value greater than Outstanding QTY';
      setErrors(newErrors);
    } else {
      // Input value is valid, proceed with updating state
      const updatedList = [...receivedGoodsList];
      const newReceivedValue = [...receivedValue];
      newReceivedValue[index] = value.toLocaleString(); // Format value with commas
      setReceivedValue(newReceivedValue);

      const amount = value * parseFloat(item.ListUnitPriceAmount);
      updatedList[index] = { ...item, QuantityReceived: value, Amount: amount };
      setReceivedGoodsList(updatedList);

      // Clear error if input is valid
      const newErrors = [...errors];
      newErrors[index] = null;
      setErrors(newErrors);
    }
  } else {
    // Set input value to 0 for invalid input
    const newReceivedValue = [...receivedValue];
    newReceivedValue[index] = ''; // Treat invalid input as 0
    setReceivedValue(newReceivedValue);

    // Set error for invalid input value for the specific index
    const newErrors = [...errors];
    newErrors[index] = 'Invalid input value';
    setErrors(newErrors);
  }
};
const formatCurrency = (amount) => {
  return ((amount / 100) * 100 ).toLocaleString('en-US', {
    style: 'currency',
    currency: 'NGN',
    currencyDisplay: 'narrowSymbol', // or 'code' for displaying "NGN" without the symbol
  });
};

  
  const check = results.some((item) => item.extra_fields && item.extraField);
  console.log(completeDelivery, "complete");
  const [tableHeader, setTableHeader] = useState([]);
  function removeUnderscores(str) {
    return str.replace(/_/g, " ");
  }

  const [taxAmounts, setTaxAmounts] = useState([]);

  const calculateTaxAmount = (taxRates, amount) => {
    if (taxRates.length > 0) {
      const totalTaxRate = taxRates.reduce((sum, tax) => sum + tax.rate, 0);
      const taxAmount = amount * (totalTaxRate / 100);
      return taxAmount;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const calculatedTaxAmounts = results.map((item) => {
      const baseAmount = item.extra
        ? multiplyNumberValues(item.extra_fields)
        : item.QuantityReceived || 0;
      const unitPrice = item.metadata?.ListUnitPriceAmount || 0;
      const amount = baseAmount * unitPrice;
      const taxAmount = calculateTaxAmount(item.tax_rates, amount);
      return taxAmount;
    });
    console.log(calculatedTaxAmounts, "calculated");
    setTaxAmounts(calculatedTaxAmounts);
  }, [results]);

  const [sum, setSum] = useState(0);
  useEffect(() => {
    const calculateSum = () => {
      if (taxAmounts.length > 0) {
        const totalSum = taxAmounts.reduce((acc, value) => acc + value, 0);
        setSum(totalSum);
      } else {
        setSum(0);
      }
    };

    calculateSum();
  }, [taxAmounts]);
  const handleView = () => {
    // Check if there are items with extra_fields but extraField is false
    console.log(results,'resuk')
    const hasIncompleteItems = results.some(
      (item) => item && item.extra_fields.length > 0 && !item.extraField
    );
  
    if (hasIncompleteItems) {
      setGraySave(true);
  
      // Show toast notification for incomplete items
      toast.error(
        "Cannot proceed to preview. Complete the form for items with extra field",
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
  
      return; // Prevent navigation
    }
  
    // Check if any item quantity received is greater than delivery_outstanding_quantity
    const excessQuantityItem = results.find((item) => {
      if (item.extra) {
        return multiplyNumberValues(item.extra_fields) > item.delivery_outstanding_quantity;
      }
      return item.QuantityReceived > item?.delivery_outstanding_quantity;
    });
  
    if (excessQuantityItem) {
      // Show toast notification for excess quantity received with item description
      toast.error(
        `Cannot proceed to preview. Quantity received for item "${excessQuantityItem.product_name}" is greater than outstanding quantity`,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
  
      return; // Prevent navigation
    }
  
    // Extract header information from receivedGoodsList
    const header = [];
  
    receivedGoodsList.forEach((item) => {
      if (item?.extraField && header.length === 0) {
        const keys = Object.keys(item.extra_fields);
        keys.forEach((subitem) => {
          header.push(subitem);
        });
      }
    });
  
    console.log(header, "geeee");
  
    // Update tableHeader state if it's empty
    if (tableHeader.length === 0) {
      setTableHeader(header);
    }
  
    // Navigate to the next page
    handleNextPage();
    GeneratUniqueNumber();
  };
  
  {hasValue !== 0 && results.length > 0 && (
    <button
      className="my-4 float-right px-3 mb-3 font-medium text-sm py-2 mx-4 border rounded-md bg-blue-700 text-white"
      onClick={handleView}
    >
      View Details
    </button>
  )}
  

  console.log(tableHeader, "ggggg");
  console.log(tableHeader, "ggggg");
  const search = async () => {
    setShowVendorSearchButton(false); // Hide the search button
    setLoading(true); // Show loading indicator
    console.log(searchTerm);
    try {
      console.log(searchTerm);
      //  const response = await axios.get(`https://20.101.63.100/egrn/v1/vendors/search?email=${searchTerm}`);
      const response = await axios.get(
        `https://20.101.63.100/egrn/v1/vendors/search?email=${searchTerm}`
      );
      console.log(response, "response");
      setAutoCompleteOptions(response.data.data.PurchaseOrders);
      setVendorID(response.data.data.BusinessPartner.InternalID);

      // setDisableAutocomplete(false)
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error fetching search results:", error);
      toast.error(error.response?.data.message);
      setError(error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  console.log(show, "showww");
  const getPOLineItems = () => {
    console.log(token, "id token");
    setLoading(true);
    axios
      .get(`https://20.101.63.100/egrn/v1/purchaseorders/${searchTerm}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        let LineItems = data;
        const updatedTotalItems = LineItems.data.data.Item.map((item) => ({
          ...item,
          discountType: "", // Initialize discountType field with an empty string
          discountValue: "", // Initialize discountValue field with an empty string
          discount: false, // Initialize discount as false
        }));
        console.log(updatedTotalItems, "updatedTotalItems"); // Check if the fields are added correctly
        setTotalItems(updatedTotalItems);

        setVendor(
          LineItems.data.data.metadata.Supplier.SupplierName[0].FormattedName
        );
        localStorage.setItem(
          "vendorname",
          LineItems.data.data.metadata.Supplier.SupplierName[0].FormattedName
        );
      })
      .catch((error) => {
        console.log(error, "PO fetching error.");
        const errorMessage =
          error?.response?.data?.detail || "Error Fetching PO Items"
            ? error?.response?.data?.detail
            : "Error Fetching PO";

        if (error?.response?.status === 401) {
          // Call handleLogout in case of 401 error
          // handleLogout(msalConfig, userName, navigate);
          setsessionExpired(true);
        } else {
          setError(errorMessage);
          setResponseError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const calculateTotalAmount = () => {
    if (!receivedGoodsList || receivedGoodsList.length === 0) {
      return 0; // Return 0 if the received goods list is empty or undefined
    }

    let total = 0;

    results.forEach((item) => {
      const quantityReceived = item.extra
        ? multiplyNumberValues(item.extra_fields)
        : parseFloat(item.QuantityReceived) || 0; // Parse QuantityReceived as float or default to 0
      const unitPriceAmount =
        parseFloat(item?.metadata?.ListUnitPriceAmount) || 0; // Parse ListUnitPriceAmount as float or default to 0

      total += quantityReceived * unitPriceAmount;
    });

    // Return the total as a floating-point number without rounding
    return total;
  };

  console.log(POLineItems);
  useEffect(() => {
    if (receivedValue) {
      console.log(receivedValue, "prr");
      const newTotalReceivedGoods = receivedValue.reduce((acc, value) => {
        const parsedValue =
          value !== null
            ? typeof value === "number" || /^\d*\.?\d+$/.test(value)
              ? parseFloat(value)
              : 0
            : 0;

        console.log(parsedValue, "parr");
        return acc + (!isNaN(parsedValue) ? parsedValue : 0); // If parsedValue is NaN, default to 0
      }, 0);

      setTotalReceivedGoods(newTotalReceivedGoods);
      console.log(newTotalReceivedGoods, "parri");
    } else {
      setTotalReceivedGoods(0); // Set total to 0 if receivedValue is null or undefined
    }

    setRecievedItemAmont(calculateTotalAmount());
    console.log(recievedItemAmount, "ttttt");
  }, [receivedValue, calculateTotalAmount]);
  console.log("abcd");

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    console.log(receivedGoodsList, "receivedgood list");
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleAutofill = () => {
    // Toggle the completeDelivery state
    setCompleteDelivery((prevCompleteDelivery) => {
      const newCompleteDelivery = !prevCompleteDelivery;
      
      // Filter items with delivery_outstanding_quantity greater than 0
      const outstandingItems = totalitems.filter(
        (item) => item.delivery_outstanding_quantity > 0
      );
      console.log(outstandingItems, 'outstanding items');
      
      if (newCompleteDelivery) {
        // If completeDelivery is true, update receivedGoodsList and receivedValue
        const updatedReceivedGoods = outstandingItems.map((item) => ({
          ...item,
          QuantityReceived: item.delivery_outstanding_quantity, // Keep this as a number for calculations
          Amount: item.delivery_outstanding_quantity * item.ListUnitPriceAmount,
        }));
        setReceivedGoodsList(updatedReceivedGoods);
  
        const updatedReceivedValue = totalitems.map((item) =>
          formatNumber(item.delivery_outstanding_quantity) // Format as comma-separated
        );
        setReceivedValue(updatedReceivedValue);
  
        console.log(updatedReceivedGoods, "updatedReceivedGoods");
        console.log(updatedReceivedValue, "updatedReceivedValue");
      } else {
        // Clear lists and errors when completeDelivery is false
        setReceivedGoodsList([]);
        setReceivedValue([]);
        setErrors([]);
      }
  
      // Output the new value of completeDelivery
      console.log(newCompleteDelivery);
      return newCompleteDelivery;
    });
  };
  


  
  const hasextra = results.some((item) => item.extraField);
  const currentDate = new Date().toLocaleDateString();

  const handleSaveAsDraft = () => {
    // Get the current date
    const currentDate = new Date().toLocaleDateString();
    console.log("currentdatt", currentDate);
    // Prepare received goods data
    const receivedGoodsData = results.map((item) => ({
      quantityReceived: item.QuantityReceived,
      itemObjectID: item.ObjectID,
      Description: item.Description,
      ProductCode: item.ProductTypeCode,
      Amount: item.NetAmount,
      Quantity: item.Quantity,
    }));

    // Create the draft object
    const draft = {
      email: searchTerm,
      PONumber: selectedPO.ID,
      POID: selectedPO.ObjectID,
      GRN: grnGenerated,
      currentDate: currentDate,
      status: "Draft",
      totalAmount: calculateTotalAmount(),
      receivedGoods: receivedGoodsData,
    };
    console.log(draft);
    // Retrieve existing drafts from local storage or initialize an empty array if not found
    const existingDrafts = JSON.parse(localStorage.getItem("Drafted")) || [];

    // Append the new draft to the existing list
    existingDrafts.unshift(draft);

    // Store the updated list of drafts back in local storage
    localStorage.setItem("Drafted", JSON.stringify(existingDrafts));

    // Navigate to the history page
    setShowModal(true);
  };

  const handleViewValueClick = () => {
    setTableHeader([]);
    setErrors([]);
    setClickedIndex(null);
    setReceivedGoodsList([]);
    setInputValues([]);
    setReceivedValue([]);
    setPOLineItems([]);
    getPOLineItems();
    generateRandomSixDigitNumber();
    setCompleteDelivery(false);
    

    setShowValue(true);
  };
  const handleGenerate = () => {
    setShowGenerateModal(false);

    const currentDate = new Date().toLocaleDateString();
    const receivedGoodsData = [];

    receivedGoodsList.forEach((item) => {
      // Check if item is defined and not null before accessing its properties
      if (item) {
        const receivedItemData = {
          quantityReceived: item.extra?0:item.QuantityReceived,
          itemObjectID: item?.metadata.ObjectID,
          taxAmount: calculateTaxAmount(
            item.tax_rates,
            (item.extra
              ? multiplyNumberValues(item.extra_fields)
              : item.QuantityReceived) * item?.metadata.ListUnitPriceAmount
          ),
        };

        if (item.extra_fields) {
          receivedItemData.extra_fields = item.extra_fields.reduce(
            (acc, field) => {
              acc[field.name] = parseFloat(field.value);
              return acc;
            },
            {}
          );
        }
        console.log(receivedItemData, "RECEIVEDITEM");
        receivedGoodsData.push(receivedItemData);
      } else {
        // Handle the case where item is undefined or null
        // For example, you could log a message or perform some other action
        console.log("Item is undefined or null");
      }
    });

    /*receivedGoodsList.forEach((item) => {
    const receivedItemData = {
      quantityReceived: item.QuantityReceived,
      itemObjectID: item.ObjectID,
      Description:item.Description,
      
      ProductCode:item.ProductTypeCode,
      UnitPrice:item.ListUnitPriceAmount
    };
    receivedGoodsData.push(receivedItemData);
  });*/
    const newSubmission = {
      //totalAmount:calculateTotalAmount(),
      //currentDate:currentDate,
      GRN: grnGenerated,
      POID: searchTerm,
      PONumber: searchTerm,
      recievedGoods: receivedGoodsData,
      send_email: false,
    };

    console.log("new submission", newSubmission);
    localStorage.setItem("InvoiceData", JSON.stringify(newSubmission));
    // Retrieve existing submissions from local storage
    setInvoiceData(newSubmission);
    const existingSubmissionsJSON = localStorage.getItem("Submitted");

    // Check if existing submissions exist and parse them
    let existingSubmissions = [];
    if (existingSubmissionsJSON) {
      existingSubmissions = JSON.parse(existingSubmissionsJSON);
    }

    // Append the new submission to the existing list
    existingSubmissions.unshift(newSubmission);

    // Store the updated list of submissions back in local storage
    localStorage.setItem("Submitted", JSON.stringify(existingSubmissions));

    console.log(isConfirm, "confirm");
    // if (!isConfirm){
    console.log(isConfirm, "yes");

    createGRN(newSubmission);

    // }
    console.log(receivedGoodsList, "received goods list");
  };

  const generateRandomSixDigitNumber = () => {
    const min = 100000; // Minimum value for a six-digit number
    const max = 999999; // Maximum value for a six-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const createGRN = async (x) => {
    console.log(x, "data"); // Logging the passed data

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/egrn/v1/grn`,
        x,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // Ensure that response.data exists and is not undefined
      if (response.status === 201) {
        localStorage.setItem(
          "PostResponse",
          JSON.stringify(response.data.data)
        );
        console.log(response.data);
        setShowModal(true);
      } else {
        // Handle error case here, for example, displaying a notification
        // You can use a library like Swal (SweetAlert) for this purpose
      }

      console.log(response.status, "res");
    } catch (error) {
      // Handle error if the request
      console.log(error.response);
      setError(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
      console.error(
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message
      );
      setShowError(true);
    } finally {
      setLoading(false); // Reset loading state after request completion
    }
    setLoading(false);
  };
  console.log(receivedGoodsList, "prrr");
  const [selectedItemIndex, setSelectedItemIndex] = useState(null); // State to track selected item index // State to track selected item index
  const [selectedItemFields, setSelectedItemFields] = useState([]);

  // Function to handle button click
  const [clickedIndex, setClickedIndex] = useState(null);

  useEffect(() => {
    console.log(selectedItemIndex, "shoppp");
    console.log(selectedItemFields, "shoppp");
  }, [selectedItemIndex]);

  // Function to close modal
  const handleCloseModal = () => {
    setSelectedItemIndex(null);
    setSelectedItemFields([]);
  };
  const [isOpen, setIsOpen] = useState(false);
  // const hasExtraField = receivedGoodsList.some(value => value.extra_fields?value.extraField:true);
  const [discountType, setDiscountType] = useState("unit");
  const [discountValue, setDiscountValue] = useState("");

  const [items, setItems] = useState(totalitems);
  console.log(items, "items");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [checkedItemIndex, setCheckedItemIndex] = useState(null);
  // console.log(hasExtraField,'extrafield')
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const handleCheckboxChange = (index) => {
    console.log(index, "index");
    if (!receivedValue[index]) {
      toast.error("Input a valid value for quantity received");
      return;
    }
    setCheckedItemIndex(index); // Update the state with the index of the checked item
    setIsDiscountModalOpen(true); // Open the modal
  };
  const [inputValues, setInputValues] = useState({});

  const handleModalSubmit = (e) => {
    e.preventDefault();
    handleExtraFieldButtonClick(selectedItemFields, selectedItemIndex);
    handleCloseModal();
  };
  console.log(inputValues, "inputvalues");

  const handleInputChange = (itemIndex, fieldIndex, value) => {
    setInputValues((prevValues) => {
      const updatedValues = { ...prevValues };

      console.log(updatedValues, "inputvalues");
      if (!updatedValues[itemIndex]) {
        updatedValues[itemIndex] = Array(selectedItemFields.length).fill("");
      }
      updatedValues[itemIndex][fieldIndex] = value;
      return updatedValues;
    });
  };
  const handleButtonClick = (item, extraFields, index) => {
    console.log(item.extra_fields, "index");
    const updatedList = [...receivedGoodsList];
    const newReceivedValue = [...receivedValue];
    newReceivedValue[index] = 0; // Update value with trimmed input
    setReceivedValue(newReceivedValue);
    updatedList[index] = { ...item };
    setReceivedGoodsList(updatedList);
    setSelectedItemIndex(index);
    console.log(selectedItemIndex, "sellll");
    setSelectedItemFields(extraFields);
    setIsOpen(true);
  };
  const handleExtraFieldButtonClick = (extraFields, index) => {
    console.log(extraFields, "extrafields");
    const updatedReceivedGoodsList = [...receivedGoodsList];
    const newReceivedValue = [...receivedValue];
    console.log(receivedGoodsList, "extrafields");
    if (updatedReceivedGoodsList[index]) {
      const extraFieldsWithValues = extraFields.map((field, idx) => {
        console.log(field, "index of value");

        return {
          ...field,
          value: inputValues[index][idx],
        };
      });

      console.log(extraFieldsWithValues, "extraFieldsWithValues");
      updatedReceivedGoodsList[index].extra_fields = extraFieldsWithValues;
      updatedReceivedGoodsList[index].extraField = true;
      updatedReceivedGoodsList[index].extra = true;
    updatedReceivedGoodsList[index].QuantityReceived = 100;
      setReceivedGoodsList(updatedReceivedGoodsList);
      newReceivedValue[index] = multiplyNumberValues(
        receivedGoodsList[index].extra_fields
      );
      setReceivedValue(newReceivedValue);
      console.log(updatedReceivedGoodsList, "extraFieldsWithValues");
    }
  };

  console.log(inputValues, "iiii");

  const handleModalCLose = () => {
    setIsDiscountModalOpen(false); // Close the modal
  };
  const handleDiscountModalSubmit = (e) => {
    e.preventDefault();

    // Get the item that is currently selected
    const selectedItem = totalitems[checkedItemIndex];

    // Update the selected item with the discount type and value
    selectedItem.discountType = discountType;
    selectedItem.discountValue = discountValue;
    selectedItem.discount = true;

    // Also update the receivedGoodsList with the discount information
    const updatedReceivedGoodsList = [...receivedGoodsList];
    if (updatedReceivedGoodsList[checkedItemIndex]) {
      updatedReceivedGoodsList[checkedItemIndex].discountType = discountType;
      updatedReceivedGoodsList[checkedItemIndex].discountValue = discountValue;
      updatedReceivedGoodsList[checkedItemIndex].discount = true;
    }

    // Update the state with the new received goods list
    setReceivedGoodsList(updatedReceivedGoodsList);

    // Log the updated item (for debugging purposes)
    console.log("Updated item with discount:", selectedItem);

    // Close the discount modal after submission
    handleModalCLose();
  };

  function multiplyNumberValues(items) {
    if (!items || items.length === 0) return 0; // Handle case with no items

    // Convert the value properties to numbers and multiply them together, excluding 'product_volume'
    const product = items.reduce((acc, item) => {
      if (item.name === 'product_volume') return acc; // Skip 'product_volume'
      const value = parseFloat(item.value);
      return acc * (isNaN(value) ? 0 : value); // Ensure non-numeric values don't affect the product
    }, 1); // Start with 1 as the initial value for multiplication

    return product;
}

  return (
    <>
      <div>
        {error && (
          <Error
            show={showErrow}
            message={error}
            handleShow={() => {
              setShowError(!showErrow);
            }}
          />
        )}
        {loading && <Loader />}
        {!sessionExpired ? (
          <>
            <Header />

            <div className="flex main-body">
              <div className="sidebar hidden md:block">
                <Sidebar />
              </div>
              <div className="content md:w-[96%] w-full md:ml-[14%] p-3">
                <PageTitle pagetitle="Create GRN" />
                <div className="content-container flex flex-col box-shadow my-4">
                  {currentPage === 1 && (
                    <>
                      <div>
                        <div className="content-container  flex flex-col box-shadow ">
                          <div className="content-container-title text-white  bg-blue-800 flex justify-between items-center px-4 text-base">
                            Purchase Order
                          </div>
                          <div className="text-base  text-[#545454] bg-white  ">
                            <div className="flex  justify-center items-center px-3 gap-2 py-5 bg-white">
                              {/* <div class="relative w-full flex">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                              </svg>
                          </div>
                          <input type="text" id="simple-search" class=" self-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-2/5 pl-10 p-2 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500" placeholder="Search" required=""
                          value={receivedData ? receivedData.email : searchTerm}
                          onChange={handleVendorSearchBoxChange}
                          />
                      </div> */}
                              <Paper
                                component="form"
                                sx={{
                                  p: "2px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                  width: 400,
                                }}
                                className="border"
                              >
                                <SearchIcon />
                                <InputBase
                                  sx={{ ml: 1, flex: 1, py: "2px" }}
                                  placeholder="Search with PO number"
                                  inputProps={{
                                    "aria-label": "Search with PO number",
                                  }}
                                  value={
                                    receivedData
                                      ? receivedData.email
                                      : searchTerm
                                  }
                                  onChange={handleVendorSearchBoxChange}
                                  className="py-2"
                                />
                              </Paper>
                              <button
                                onClick={handleViewValueClick}
                                className={`font-medium py-2 text-sm h-[44px] mt-1 px-3 border rounded-md bg-blue-700 text-white`}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {showValue && (
                        <>
                          <Modal
                            onClose={handleModalCLose}
                            open={isDiscountModalOpen}
                          >
                            <Box sx={style}>
                              <div className="relative bg-white rounded-lg shadow ">
                                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
                                  <h3 className="text-xl font-semibold text-gray-900 ">
                                    Input Discount Details
                                  </h3>
                                  <button
                                    type="button"
                                    className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center  hover:text-white"
                                    data-modal-hide="authentication-modal"
                                    onClick={handleModalCLose} // Close modal on button click
                                  >
                                    <svg
                                      className="w-3 h-3"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 14 14"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                      />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                  </button>
                                </div>
                                <div className="p-4 md:p-5">
                                  <form
                                    className="space-y-4"
                                    onSubmit={handleDiscountModalSubmit}
                                  >
                                    {/* Select input for discount type */}
                                    <div>
                                      <label
                                        htmlFor="discountType"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Discount Type
                                      </label>
                                      <select
                                        id="discountType"
                                        name="discountType"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={discountType}
                                        onChange={(e) =>
                                          setDiscountType(e.target.value)
                                        }
                                      >
                                        <option value="unit">Unit</option>
                                        <option value="value">Value</option>
                                        <option value="percentage">
                                          Percentage
                                        </option>
                                      </select>
                                    </div>
                                    {/* Text input for discount value */}
                                    <div>
                                      <label
                                        htmlFor="discountValue"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Discount Value
                                      </label>
                                      <input
                                        type="text"
                                        id="discountValue"
                                        name="discountValue"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={discountValue}
                                        onChange={(e) =>
                                          setDiscountValue(e.target.value)
                                        }
                                      />
                                    </div>
                                    {/* Form submission button */}
                                    <button
                                      type="submit"
                                      className="w-full text-white  font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-4 focus:outline-none  bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                                    >
                                      Submit
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </Box>
                          </Modal>

                          {selectedItemIndex !== null && (
                            <Modal onClose={handleCloseModal} open={true}>
                              <Box sx={style}>
                                <div className="relative bg-white rounded-lg shadow ">
                                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-300">
                                    <h3 className="text-xl font-semibold text-gray-900 ">
                                      Complete the Item check
                                    </h3>
                                    <button
                                      type="button"
                                      className="end-2.5 text-gray-400 bg-transparent  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white"
                                      data-modal-hide="authentication-modal"
                                      onClick={handleCloseModal}
                                    >
                                      <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                      >
                                        <path
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                      </svg>
                                      <span className="sr-only">
                                        Close modal
                                      </span>
                                    </button>
                                  </div>
                                  <div className="p-4 md:p-5">
                                    <form
                                      className="space-y-4"
                                      onSubmit={handleModalSubmit}
                                    >
                                    {selectedItemFields.map((item, index) => (
  <div key={index}>
    <label
      htmlFor={`field${index}`}
      className="block mb-2 text-sm font-medium text-gray-900 "
    >
      {removeUnderscores(
        item.name.charAt(0).toUpperCase() + item.name.slice(1)
      )}
    </label>
    {item.type === "number" ? (
      <input
        type="text"
        name={`field${index}`}
        id={`field${index}`}
        className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  placeholder-gray-400 "
        placeholder={`Enter ${removeUnderscores(item.name)}`}
        required
        value={
          inputValues[selectedItemIndex]
            ? inputValues[selectedItemIndex][index]
            : ""
        }
        onChange={(e) =>
          handleInputChange(selectedItemIndex, index, e.target.value)
        }
      />
    ) : item.type === "select" ? (
      <select
        name={`field${index}`}
        id={`field${index}`}
        className="bg-gray-50 border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  border-gray-300 placeholder-gray-400 "
        required
        value={
          inputValues[selectedItemIndex]
            ? inputValues[selectedItemIndex][index]
            : ""
        }
        onChange={(e) =>
          handleInputChange(selectedItemIndex, index, e.target.value)
        }
      >
        <option value="" className="hidden"></option> {/* Empty option */}
        {(item.properties?.options || item.options)?.map((option, optionIndex) => (
          <option key={optionIndex} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    ) : null}
  </div>
))}


                                      <button
                                        type="submit"
                                        className="w-full text-white bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-4 focus:outline-none  hover:bg-blue-800 focus:ring-blue-300 "
                                      >
                                        Submit
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </Box>
                            </Modal>
                          )}

                          <div className=" py-4 bg-white my-4 px-4 md:px-0">
                            <div class="mx-auto max-w-screen-xl px-4 md:px-0 lg:px-4">
                              <div className=" font-poppins text-[#545454] text-base font-medium leading-10 flex flex-col md:flex-row justify-between  mb-3">
                                <span>
                                  Enter the quantity of goods received
                                </span>
                                <button
                                  type="button"
                                  class="flex items-center justify-center text-white bg-blue-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-primary-800"
                                  onClick={handleAutofill}
                                >
                                  Complete Delivery
                                </button>
                              </div>
                              <div class="bg-white relative sm:rounded-lg overflow-auto">
                                <div className="flow-root  md:mt-0">
                                  <div className="inline-block min-w-full align-middle">
                                    <div className="rounded-lg md:pt-0">
                                      <table className="min-w-full text-gray-900 md:table shadow border ">
                                        <thead>
                                          <tr className="font-normal text-sm text-[#706f6f] ">
                                            <th className="border-b border-r text-center       py-2 pl-4">
                                              Purchase Order
                                            </th>
                                            <th className="border-b border-r md:py-2 pl-4">
                                              Receive Goods
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="border-r ">
                                              <table className="w-full">
                                                <thead className="text-[#545454] text-sm border-b my-3">
                                                  <tr>
                                                    {/* <td className="py-2 text-sm font-medium px-4 w-12">SN</td> */}
                                                    <td className="py-2 text-sm font-medium px-2">
                                                      Description
                                                    </td>
                                                    <td className="py-2 text-sm  font-medium px-2">
                                                      Product Code
                                                    </td>
                                                    <td className="py-2 text-sm font-medium px-2">
                                                      UoM
                                                    </td>
                                                    <td className="py-2 text-sm font-medium text-right pr-5">
                                                      Outstanding Qty
                                                    </td>
                                              
                                                    <td className="py-2 text-sm font-medium text-right pr-7">
                                                      Unit Price{" "}
                                                    </td>
                                                    <td className="py-2 text-sm font-medium text-right pr-4 ">
                                                      Amount{" "}
                                                    </td>
                                                    <td className="py-2 text-sm font-medium text-right pr-2 ">
                                                      Tax{" "}
                                                    </td>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {totalitems.map(
                                                    (item, index) => (
                                                      <tr
                                                        key={index}
                                                        className="text-sm leading-7 h-12 border-b border-gray-200"
                                                      >
                                                        <td className="py-2 text-sm px-2">
                                                          {item?.metadata
                                                            .Description ||
                                                            "No description provided"}
                                                        </td>
                                                        <td className="py-2 text-sm px-2">
                                                          {
                                                            item?.metadata
                                                              .ProductID
                                                          }
                                                        </td>
                                                        <td className="py-2 text-sm px-2">
                                                        {
                                              item?.metadata
                                                .NetUnitPriceBaseUnitCode
                                            }
                                                        </td>
                                                        <td className="py-2 text-right pr-7">
                                                          <NumericFormat
                                                            value={
                                                              item.delivery_outstanding_quantity
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            decimalSeparator="."
                                                            decimalScale={3}
                                                            fixedDecimalScale
                                                          />
                                                         
                                                        </td>
                                                        <td className="py-2 text-sm text-right pr-8">
                                                          <NumericFormat
                                                            value={
                                                              item?.metadata
                                                                .ListUnitPriceAmount
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            decimalSeparator="."
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                          />
                                                        </td>
                                                        <td className="text-right pr-2 py-2 text-sm">
                                                          <NumericFormat
                                                            value={
                                                              item?.metadata
                                                                .ListUnitPriceAmount *
                                                              item.delivery_outstanding_quantity
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            decimalSeparator="."
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                          />
                                                        </td>
                                                        <td className="text-right pr-2 py-2 text-sm">
                                                          <NumericFormat
                                                            value={
                                                              item?.metadata
                                                                .TaxAmount
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            decimalSeparator="."
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                          />
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </td>
                                            <td className="overflow-y-auto">
                                              <table className="w-full ">
                                                <thead className="text-[#545454] font-medium text-sm border-b ">
                                                  <tr>
                                                    <td className="py-3 md:py-2 text-sm font-medium pl-4 ">
                                                      Quantity
                                                    </td>
                                                    {/* {Extrafiled && <td className="py-2 text-sm font-medium pl-5">Additional Field</td>} */}
                                                    {/* <td className="py-2 text-sm font-medium pl-4 w-12">Discount</td> */}
                                                    <td className="py-2 text-sm text-right font-medium pr-2">
                                                      Amount
                                                    </td>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                {totalitems.map((item, index) => (
  <tr key={index} className="text-sm leading-7 h-12 border-b border-gray-200">
    <td className="py-1">
      {totalitems.length > index &&
        (item.extra_fields.length > 0 ? (
          <button
            className={`bg-blue-700 px-3 py-1 border h-9 mx-3 w-28 font-normal text-sm text-center rounded-md ${
              item.delivery_outstanding_quantity === 0 ? "bg-gray-200 text-gray-700" : "text-white"
            }`}
            disabled={item.delivery_outstanding_quantity === 0}
            onClick={() => handleButtonClick(item, item.extra_fields, index)}
          >
            Show more
          </button>
        ) : (
          <>
            <div className="">
              <input
                type="text"
                disabled={item.delivery_outstanding_quantity <= 0}
                id={`quantity_${index}`}
                className={`border h-8 text-right pr-4 text-sm w-28 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none mx-3 ${clickedIndex === index ? 'border-red-300 ' : 'border-gray-300 '}`}
                value={receivedValue.length > 0 ? receivedValue[index] : ''}
                onChange={(event) => handleQuantityInputChange(index, event)}
              />
              {errors[index] && <p className="text-red-500 text-xs mx-3 mt-1">{errors[index]}</p>}
            </div>
          </>
        ))}
    </td>
    <td className="text-right pr-2 py-2 text-sm">
         <NumericFormat
        value={
          (parseFloat(String(receivedValue[index] || '').replace(/,/g, '')) || 0) * item?.metadata.ListUnitPriceAmount
        }
        displayType={"text"}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />

    </td>
  </tr>
))}
</tbody>

                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="w-full">
                                        <tbody>
                                          <tr className="flex justify-between w-full bg-blue-800 ">
                                            <td className="px-3 py-2 text-white flex gap-32">
                                              <span>Total PO Value</span>
                                              <span>
                                                {
                                                  <NumericFormat
                                                    value={calculateTotalPOValue()}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalSeparator="."
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                  />
                                                }
                                              </span>
                                            </td>
                                            <td
                                              className="px-3 py-2 text-white flex justify-between  gap-32"
                                              colSpan="2"
                                            >
                                              <span>Total Received Goods</span>

                                              <span>
                                                {
                                                  <NumericFormat
                                                    value={
                                                     calculateTotalAmount()
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalSeparator="."
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                  />
                                                }
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {hasValue !== 0 && results.length > 0 && (
                              <button
                                className={`my-4 float-right px-3 mb-3 font-medium text-sm py-2 mx-4 border rounded-md bg-blue-700 text-white`}
                                onClick={handleView}
                              >
                                View Details
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {currentPage === 2 && (
                    <div>
                      <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                      >
                        <Box sx={style}>
                          <div className="flex items-center flex-col justify-center">
                            <img src={ApprovalStatusImage} alt="" />
                            <div className="text-center      py-2 text-base ">
                              GRN created succesfully click the button below to
                              view!
                            </div>
                            <button
                              className="my-2 bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
                              onClick={() => {
                                navigate("/grn");
                              }}
                            >
                              Okay
                            </button>
                          </div>
                        </Box>
                      </Modal>
                      <Modal
                        open={showGenerateModal}
                        onClose={() => setShowGenerateModal(false)}
                        centered
                      >
                        <Box sx={style}>
                          <div className="flex items-center flex-col justify-center">
                            <img src={InformationIcon} alt="" />
                            <div className="text-center      py-2 text-base ">
                              Do you want to generate the GRN
                            </div>
                            <div className="flex gap-4">
                              <button
                                className="my-2 bg-blue-600 text-white py-2 px-2 w-24 rounded-md hover:bg-blue-700"
                                onClick={handleGenerate}
                              >
                                Yes
                              </button>
                              <button
                                className="my-2 bg-red-600 text-white py-2 px-2 w-24 rounded-md hover:bg-red-700"
                                onClick={() => {
                                  setShowGenerateModal(false);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Box>
                      </Modal>

                      <div className="content-container bg-white flex flex-col box-shadow">
                        <div className="flex bg-blue-800 text-white p-2 gap-3 text-base">
                          <button
                            className="bg-transparent border-none"
                            onClick={handlePrevPage}
                          >
                            <ArrowLeft />
                          </button>
                          <span>GRN Preview</span>
                        </div>
                        <>
                          <div className=" items-center flex flex-col md:flex-row bg-white p-4">
                            <div className="flex flex-col items-center">
                              <img
                                className=" h-24 md:h-32"
                                src={Logo}
                                alt=""
                              />
                              <p className="font-semibold text-lg md:text-2xl -mt-8 text-[#2957A4]">
                                Food Concepts
                              </p>
                            </div>
                            <div className="flex flex-col items-center self-center justify-center">
                              <p className="font-semibold text-xl text-center m:text-3xl  text-blue-800 md:ml-16 my-2 md:my-4">
                                Food Concepts (Chicken Republic)
                              </p>
                              <p className="text-[#474646] font-normal text-center text-base md:ml-16">
                                <span className="underline  md:pb-3">
                                  2, Ilupeju Bye Pass, Ilupeju, Lagos, Nigeria{" "}
                                </span>
                                Tel: +234 (0) 809 016 5972 | +234 (0) 809 016
                                5973
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col md:flex-row justify-between items-center text-[#545454] text-base p-4">
                            <ul className="m-0 p-0 justify-end text-sm">
                              <li className="flex gap-6 text-justify">
                                <span className="flex-grow">
                                  Received from:
                                </span>
                                <span className="text-justify">{vendor}</span>
                              </li>
                              <li className="flex gap-6 text-justify">
                                <span className="">Store Name:</span>
                                <span className="">{deliveryLocation}</span>
                              </li>
                            </ul>

                            <div className="border border-gray-200 font-normal my-5 md:my-3 flex flex-col">
                              <div className="border-b border-gray-200  flex  p-2 m-0">
                                <span>Date</span>
                                <span className=" text-right flex-grow ">
                                  {getFormattedDate()}
                                </span>
                              </div>
                              {/* <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
          <span>GRN Number</span>
          <span className="text-right flex-grow ">{grnGenerated}</span>
        </div> */}
                              <div className="flex gap-36 m-0 p-2">
                                <span>Purchase Order</span>
                                <span className="flex-grow text-right">
                                  {searchTerm}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>

                        <section class="bg-gray-50 ">
                          <div class="mx-auto ">
                            <div class="bg-white  relative  overflow-hidden">
                              <div class="overflow-x-auto">
                                <table className="min-w-full text-gray-900 md:table shadow">
                                  <thead className="rounded-lg text-left bg-blue-800 text-sm font-normal">
                                    <tr className="font-poppins text-19 font-semibold leading-29">
                                      <td class="px-2 py-3 text-sm font-normal text-white">
                                        SN
                                      </td>
                                      <td class="px-2 py-3 font-normal text-white">
                                        Product Description
                                      </td>
                                      <td class="px-2 py-3 font-normal text-white">
                                        Product Code
                                      </td>
                                      <td class="px-2 py-3 font-normal text-white">
                                       UoM
                                      </td>
                                      {/* <th className="py-2 text-center text-white">
          Oustanding QTY
      </th> */}
                                      <td class="px-2 py-3 font-normal text-white text-right pr-5">
                                       QTY Outstanding
                                      </td>
                                      <td class="px-2 py-3 font-normal text-white text-right pr-5">
                                        QTY Received
                                      </td>

                                      <td class="px-2 py-3 font-normal text-white text-right pr-4">
                                        Unit Price
                                      </td>
                                      <td className="py-3 text-right  font-normal px-2 text-white">
                                        Net Value
                                      </td>
                                      <td className="py-3 text-right pr-4 font-normal px-2 text-white">
                                        Tax
                                      </td>
                                      {/* 
      {check &&
      <td class="px-2 py-3 pl-8 font-normal text-white ">
      Additional Fields
 </td>
      } */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {results.map((item, index) => (
                                      <tr
                                        className="w-full border-b py-2 text-sm last-of-type:border-none"
                                        key={index}
                                      >
                                        <td className="px-2 py-3">
                                          {index + 1}
                                        </td>
                                        <td className="px-2 py-3">
                                          {item?.metadata.Description}
                                        </td>
                                        <td className="px-2 py-3">
                                          {item?.metadata.ProductID}
                                        </td>
                                        <td className="px-2 py-3">
                                        {
                                              item?.metadata
                                                .NetUnitPriceBaseUnitCode
                                            }
                                        </td>
                                        <td className="py-3 text-right pr-8">
                                          <NumericFormat
                                            value={item?.delivery_outstanding_quantity}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                            decimalScale={3}
                                            fixedDecimalScale
                                          />
                                        </td>
                                        <td className="px-2 py-3 text-right pr-8">
                                          <NumericFormat
                                            value={
                                              item.extra
                                                ? multiplyNumberValues(
                                                    item.extra_fields
                                                  )
                                                : item.QuantityReceived
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                            decimalScale={3}
                                            fixedDecimalScale
                                          />
                                        </td>
                                        <td className="px-2 py-3 text-right pr-4">
                                          <NumericFormat
                                            value={
                                              item?.metadata.ListUnitPriceAmount
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                            decimalScale={2}
                                            fixedDecimalScale
                                          />
                                        </td>
                                        <td className="text-right ">
                                          <NumericFormat
                                            value={
                                              (item.extra
                                                ? multiplyNumberValues(
                                                    item.extra_fields
                                                  )
                                                : item.QuantityReceived) *
                                              item?.metadata.ListUnitPriceAmount
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                            decimalScale={2}
                                            fixedDecimalScale
                                          />
                                        </td>
                                        <td className="text-right pr-4 py-2 text-sm">
                                          <NumericFormat
                                            value={calculateTaxAmount(
                                              item.tax_rates,
                                              (item.extra
                                                ? multiplyNumberValues(
                                                    item.extra_fields
                                                  )
                                                : item.QuantityReceived) *
                                                item?.metadata
                                                  .ListUnitPriceAmount
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                            decimalScale={2}
                                            fixedDecimalScale
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                    <tr className="border-b border-gray-200">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className=" py-3 pl-6">Sub Total</td>
                                      <td className="text-right pr-4 py-3">
                                        <NumericFormat
                                          value={calculateTotalAmount()}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalSeparator="."
                                          decimalScale={2}
                                          fixedDecimalScale
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-b border-gray-200">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className=" py-3 pl-6">Tax</td>
                                      <td className="text-right pr-4 py-3">
                                        <NumericFormat
                                          value={sum}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalSeparator="."
                                          decimalScale={2}
                                          fixedDecimalScale
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-b border-gray-200">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="pl-6 py-3 ">
                                        Grand Total
                                      </td>
                                      <td className="text-right pr-4 py-3">
                                        <NumericFormat
                                          value={calculateTotalAmount() + sum}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalSeparator="."
                                          decimalScale={2}
                                          fixedDecimalScale
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div className="mt-6 mb-4 mx-4 inline-block">
                          <input
                            type="checkbox"
                            id="myCheckbox"
                            className="form-checkbox border-0 py-2 h-3 w-3 bg-blue-900"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                          />
                          <label
                            htmlFor="myCheckbox"
                            className="ml-2  text-[#545454] py-2 text-[15px]"
                          >
                            I confirm that I've reviewed and validated this GRN.
                            Once submitted, no changes can be made.
                          </label>
                        </div>
                        {isChecked && (
                          <button
                            className="flex self-end mb-4 mx-4 bg-blue-700 rounded text-white py-2 px-6"
                            onClick={() => {
                              setShowGenerateModal(true);
                            }}
                          >
                            Generate
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <SessionExpired />
        )}
      </div>
    </>
  );
};

export default CreateGRN;