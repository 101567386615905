import React from 'react';
import { ReactComponent as Vector } from '../assets/images/Vector.svg';
import { ReactComponent as Vector1 } from '../assets/images/Vector (1).svg';
import { ReactComponent as Vector2 } from '../assets/images/Vector (2).svg';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../assets/images/FoodConcepts_Logo-1 1.png';

const Sidebar = () => {
  const location = useLocation();
  console.log(location.pathname)
  return (
    <ul className="text-base font-medium sidebar shadow">

                 <img src={Logo} className="md:px-3 md:my-[1.2rem]" alt="" /> 

      <li className={`sidebar-text text-sm  mt-3 py-2    ${location.pathname === '/home' ? 'active' : location.pathname === '/grn' ? 'active' : ''}`}>
        <Link className='flex gap-1 items-center' to='/home'>
          <Vector className={`mx-2 icon   ${location.pathname === '/home' ? 'active' : location.pathname === '/grn' ? 'active' : ''}`} />
          <span>Create GRN</span>
        </Link>
      </li>
      <li className={`sidebar-text mt-3 text-sm py-2 ${location.pathname === '/history' ? 'active': ''}`}>
  <Link className='flex gap-1 items-center' to='/history'>
    <Vector1 className={`mx-2 icon ${location.pathname === '/history' ? 'active': ''}`} />
    <span>GRN History</span>
  </Link>
</li>
{/* <li className={`sidebar-text  mt-3 py-2  ${location.pathname === '/dashboard' ? 'active bg-blue-800' : ''}`}>
        <Link className='flex gap-1 items-center' to='/dashboard'>
          <Vector className={`mx-2 icon ${location.pathname === '/dashboard' ? 'active' : ''}`} />
          <span>Dashboard</span>
        </Link>
      </li>
      <li className={`sidebar-text mt-3 py-2 ${location.pathname === '/report' ? 'active' : location.pathname === '/invoice' ? 'active' : ''}`}>
  <Link className='flex gap-1 items-center' to='/report'>
    <Vector1 className={`mx-2 icon ${location.pathname === '/report' ? 'active' : ''}`} />
    <span>Report</span>
  </Link>
</li> */}
   
    </ul>
  );
};

export default Sidebar;
